<template>
  <div class="surplus-store">
    <div class="top-operation pb20">
      <div class="left-operation">
        <a-button
          id="supplus_store_add_btn"
          class="supplus-btn new-supplus"
          style="width: 126px; border: none"
          @click="handleShowAddSurS"
          :title="t('createNewSurplusStore')"
        >
          <span class="iconfont icon-add"></span>
          <!-- 新建余料库 -->
          <g-t-view :text="t('createNewSurplusStore')" width="100px" />
        </a-button>
        <a-button id="supplus_store_set_btn" @click="handleShowStorageSetting">
          <!-- 余料入库设置 -->
          {{ t('pushStoreSetting') }}
        </a-button>
      </div>
      <div class="right-filter">
        <a-input-search
          v-model="storeKeyWord"
          :placeholder="$t('common.inputPlaceholder')"
          @search="getSurplusStoreList()"
          @change="handleChange"
          id="supplus_store_search_input"
          allowClear
        >
          <a-select
            slot="addonBefore"
            v-model="storeSearchType"
            style="width: 90px"
            id="supplus_store_search_type_select"
          >
            <a-select-option
              id="supplus_store_search_type_select_name"
              value="name"
            >
              <!-- 余料库名 -->
              {{ t('surplusStoreName') }}
            </a-select-option>
          </a-select>
        </a-input-search>
      </div>
    </div>
    <a-table
      :columns="surplusStoreColumns"
      :data-source="surplusStoreArr"
      :row-key="
        (record, index) => {
          return index
        }
      "
      :scroll="{
        y: 'calc(100vh - 64px - 40px - 52px - 20px - 170px)',
      }"
      :pagination="false"
      class="surplus-store-table"
    >
      <template slot="selectTitle">
        <a-checkbox
          v-model="isSelectAllStore"
          id="supplus_store_list_all_checkbox"
          @change="selectAllSurplusStore()"
        ></a-checkbox>
      </template>
      <template slot="select" slot-scope="text, record, index">
        <a-checkbox
          v-model="record.isSelected"
          class="ml20"
          :id="`supplus_store_table_${index}_select_checkbox`"
          @change="handleSelectedSurplusStore(record)"
        ></a-checkbox>
      </template>
      <template slot="operation" slot-scope="text, record, index">
        <div
          style="
            display: flex;
            justify-content: space-between;
            padding-right: 15px;
          "
        >
          <span
            :id="`supplus_store_table_${index}_detail_btn`"
            @click="handleGoDetail(record)"
            class="table-operation-btn"
            style="margin-left: 30px"
          >
            <!-- 详情 -->
            <g-t-view :text="$t('common.detail')" width="28px"></g-t-view>
          </span>
          <span
            :id="`supplus_store_table_${index}_edit_btn`"
            @click="handleShowEditSurS(record)"
            class="table-operation-btn"
          >
            <!-- 编辑 -->
            <g-t-view :text="$t('common.edit')" width="28px"></g-t-view>
          </span>
          <span
            :id="`supplus_store_table_${index}_del_btn`"
            @click="handleShowDelSurS(record)"
            class="table-operation-btn"
          >
            <!-- 删除 -->
            <g-t-view :text="$t('common.delete')" width="28px"></g-t-view>
          </span>
        </div>
      </template>
    </a-table>

    <div class="supplus-pagination absolute b5">
      <div>
        <span class="color-o-6 mr15">
          <!-- 已选数据 -->
          {{
            $t('common.chooseAmountData', {
              number: selectedSurplusStoreList.length + '',
            })
          }}
        </span>
        <a-button
          @click="handleShowDelSurS({}, true)"
          :disabled="!selectedSurplusStoreList.length"
          id="supplus_store_delete-btn"
        >
          <!-- 批量删除 -->
          {{ $t('common.batchDelete') }}
        </a-button>
      </div>
      <div>
        <a-pagination
          v-model="page"
          :page-size-options="pageSizeOptions"
          :total="totalSupplus"
          show-size-changer
          :page-size="pageSize"
          @showSizeChange="onShowSizeChange"
          @change="changeCurrentPage"
          :show-total="
            (totalCount) =>
              $t('common.paginationTotalTip', {
                current: (page - 1) * pageSize + 1,
                page: page * pageSize,
                total: totalCount,
              })
          "
        >
          <template slot="buildOptionText" slot-scope="props">
            <span v-if="props.value != totalSupplus"
              >{{ props.value }}{{ $t('common.strip') }}/{{
                $t('common.page')
              }}</span
            >
            <span v-if="props.value == totalSupplus">
              <!-- 全部 -->
              {{ $t('common.all') }}
            </span>
          </template>
        </a-pagination>
        <div class="jump-to-page">
          <span>
            <!-- 跳至 -->
            {{ t('jumpTo') }}
          </span>
          <a-input
            @pressEnter="jumpToPage"
            @input="pageInputCheck"
            v-model="jumpPage"
            id="supplus_store_pagination_input"
          ></a-input>
          <span>
            {{ $t('common.page') }}
          </span>
        </div>
      </div>
    </div>

    <!-- 新建/编辑余料库弹窗 -->
    <g-base-modal
      :visible="isAddSurplusStore"
      v-if="isAddSurplusStore"
      :showIcon="false"
      :title="surplusStoreModalTitle"
      :ok-text="$t('common.confirm')"
      :isLoading="showLoading"
      @cancel="isAddSurplusStore = false"
      @ok="handleUpdateSurS"
      class="add-surStore-modal"
      preffix="surplus_store"
    >
      <div class="modal-div relative">
        <div class="modal-label">
          <span class="modal-required">*</span>
          <span class="color-o-6">
            <!-- 余料库名称 -->
            {{ t('surplusStoreName2') }}：
          </span>
        </div>
        <a-input
          v-model="surplusStoreData.name"
          class="ml8"
          style="width: 335px"
          @input="handleInputCheck('name')"
          @blur="handleInputCheck('name')"
          :class="inputAlert.name ? 'alert-input' : ''"
          id="surplus_store_name_input"
        ></a-input>
        <transition name="errorInfo">
          <span v-show="inputAlert.name" class="error-info">{{
            inputAlert.name
          }}</span>
        </transition>
      </div>
      <div class="modal-div relative">
        <div class="modal-label">
          <span class="modal-required">*</span>
          <span class="color-o-6">
            <!-- 库存上限 -->
            {{ t('inventoryCeiling') }}：
          </span>
        </div>
        <a-input
          v-model="surplusStoreData.capacity"
          class="ml8"
          style="width: 335px"
          @input="handleInputCheck('capacity')"
          @blur="handleInputCheck('capacity')"
          :class="inputAlert.capacity ? 'alert-input' : ''"
          id="surplus_store_capacity_input"
        ></a-input>
        <transition name="errorInfo">
          <span v-show="inputAlert.capacity" class="error-info">{{
            inputAlert.capacity
          }}</span>
        </transition>
      </div>
      <div class="modal-div">
        <div class="modal-label">
          <span class="color-o-6">
            <!-- 备注 -->
            {{ $t('common.remark') }}：
          </span>
        </div>
        <a-textarea
          v-model="surplusStoreData.remark"
          id="supplus_store_remark_input"
          :placeholder="$t('common.inputPlaceholder')"
          class="ml8"
          style="width: 335px"
        ></a-textarea>
      </div>
    </g-base-modal>
    <!-- 删除弹窗 -->
    <g-base-modal
      :visible="showDelSurplusStore"
      v-if="showDelSurplusStore"
      @ok="handleDelSurS"
      @cancel="showDelSurplusStore = false"
      :ok-text="$t('common.confirmDelete')"
      :contain="t('autoTransferTip')"
      :preffix="`surplus_store_${isBatchDel ? 'batch_del' : 'del'}`"
    >
    </g-base-modal>
    <!-- 余料入库设置 -->
    <g-base-modal
      :visible="showStorageSetting"
      v-if="showStorageSetting"
      :showIcon="false"
      :title="t('pushStoreSetting')"
      :ok-text="$t('common.confirm')"
      :isLoading="showLoading"
      @cancel="showStorageSetting = false"
      @ok="handleStorageSetting"
    >
      <a-radio-group
        v-model="checkedStorageSetting"
        style="display: flex; flex-direction: column"
      >
        <a-radio style="margin-bottom: 26px" :value="true">
          <!-- 按照仓库存放情况顺序入库 -->
          {{ t('asIndexPushStore') }}
        </a-radio>
        <a-radio style="margin-left: 0" :value="false">
          <!-- 存入余料清单 -->
          {{ t('pushToSurplusMaterial') }}
        </a-radio>
      </a-radio-group>
    </g-base-modal>
  </div>
</template>

<script>
import { buryPoint } from '@/apis/common/buryPoint'
import { batchSaveSurWar } from '@/apis/surplusManage/index.ts'
import GBaseModal from '@/components/g-base-modal.vue'
import GTView from '@/components/translateComp/g-t-view.vue'
import { EventBusKey } from '@/util/bus'
import EventBus from '@/util/bus'
import { translate } from '@/util/commonFun'
import { genFinalRecord } from '@/util/commonFuncs.js'

export default {
  components: {
    GBaseModal,
    GTView,
  },
  data() {
    return {
      // 记录是否显示加载
      showLoading: false,
      // 余料库
      isAddSurplusStore: false, // 新建余料库
      surplusStoreModalTitle: '新建余料库',
      // 新建/编辑余料库数据
      surplusStoreData: {
        name: '',
        capacity: '',
        remark: '',
      },
      copySurStoreData: {},
      showDelSurplusStore: false,
      showStorageSetting: false,
      checkedStorageSetting: false,
      oriCheckedStorageSetting: false,
      checkedStorageSettingTemp: false,
      storeKeyWord: '',
      storeSearchType: 'name',
      isSelectAllStore: false,
      selectedSurplusStoreList: [],
      currentClickSurplusStore: {},
      // 记录当前页码
      page: 1,
      // 记录输入的跳转页码
      jumpPage: '',
      // 记录当前一页显示条数
      pageSize: 10,
      // 记录可切换的pageSize
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      // 记录总条数
      totalSupplus: 1,
      isDeleteSurS: false,
      surplusStoreArr: [],
      surplusStoreColumns: [
        {
          dataIndex: 'select',
          key: 'select',
          width: 60,
          fixed: 'left',
          scopedSlots: { customRender: 'select' },
          slots: { title: 'selectTitle' },
        },
        {
          dataIndex: 'branch_no',
          key: 'branch_no',
          title: '编号',
          width: 100,
          align: 'center',
          translateKey: 'surplusPage.surplusStoreTable.number',
        },
        {
          dataIndex: 'name',
          key: 'name',
          title: '余料库名称',
          width: 120,
          ellipsis: true,
          align: 'center',
          translateKey: 'surplusPage.surplusStoreTable.name',
        },
        {
          dataIndex: 'quantity',
          key: 'quantity',
          title: '已存余料数量(个)',
          width: 120,
          align: 'center',
          ellipsis: true,
          translateKey: 'surplusPage.surplusStoreTable.quantity',
        },
        {
          dataIndex: 'capacity',
          key: 'capacity',
          title: '余料库存上限(个)',
          width: 120,
          align: 'center',
          ellipsis: true,
          translateKey: 'surplusPage.surplusStoreTable.capacity',
        },
        {
          dataIndex: 'remark',
          key: 'remark',
          title: '备注',
          width: 180,
          ellipsis: true,
          align: 'center',
          translateKey: 'surplusPage.surplusStoreTable.remark',
        },
        {
          dataIndex: 'operation',
          key: 'operation',
          title: '操作',
          width: 100,
          scopedSlots: { customRender: 'operation' },
          translateKey: 'surplusPage.surplusStoreTable.operation',
        },
      ],
      // 新建/编辑输入框警示
      inputAlert: {
        name: '',
        capacity: '',
      },
      inputAlertMap: {
        name: '余料库名称',
        capacity: '库存上限',
      },
      // 是否是批量删除
      isBatchDel: false,
      // 判断是否存在筛选
      isFilterStatus: false,
    }
  },
  watch: {
    totalSupplus: {
      handler() {
        this.updateTransEmpty()
      },
    },
  },
  methods: {
    handleChange(e) {
      let timer = null
      if (!e.target.value) {
        if (timer !== null) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          this.page = 1 //time用来控制事件的触发
          this.getSurplusStoreList()
        }, 500)
      }
    },
    handleShowAddSurS() {
      this.isAddSurplusStore = true
      this.surplusStoreModalTitle = '新建余料库'
      this.surplusStoreData = {
        name: '',
        capacity: '',
        remark: '',
      }
      Object.keys(this.inputAlert).forEach((key) => {
        this.inputAlert[key] = ''
      })
    },
    // 查看详情 跳转到余料清单 携带当前余料库
    handleGoDetail(surplus) {
      this.$emit('onGoDetail', surplus)
    },
    handleShowEditSurS(surplus) {
      this.isAddSurplusStore = true
      this.surplusStoreModalTitle = '编辑余料库'
      this.surplusStoreData = JSON.parse(JSON.stringify(surplus))
      this.copySurStoreData = JSON.parse(JSON.stringify(surplus))
      Object.keys(this.inputAlert).forEach((key) => {
        this.inputAlert[key] = ''
      })
    },
    handleShowDelSurS(surplus, isBatchDel) {
      this.isBatchDel = isBatchDel
      this.showDelSurplusStore = true
      this.surplusStoreData = surplus
    },
    handleUpdateSurS() {
      // 判断是否可以保存
      let ableSave = this.checkInputIsBlank()
      if (!ableSave) return
      this.isAddSurplusStore = false
      if (
        this.surplusStoreModalTitle.includes('编辑') &&
        this.copySurStoreData.name == this.surplusStoreData.name &&
        this.copySurStoreData.capacity == this.surplusStoreData.capacity &&
        this.copySurStoreData.remark == this.surplusStoreData.remark
      ) {
        return
      }
      const params = {
        surplus_branch_warehouses: [
          {
            name: this.surplusStoreData.name,
            capacity: Number(this.surplusStoreData.capacity),
            remark: this.surplusStoreData.remark,
            branch_no: this.surplusStoreData.branch_no ?? '',
          },
        ],
        create_or_update: this.surplusStoreModalTitle.includes('新建')
          ? true
          : false,
      }
      batchSaveSurWar(params).then((res) => {
        if (res.status == 1) {
          this.$message.success(
            `${
              this.surplusStoreModalTitle.includes('新建') ? '新建' : '编辑'
            }成功`
          )
          this.getSurplusStoreList()
          // 埋点及操作记录
          buryPoint({
            function_module: 'supplus_storage',
            function_point: this.surplusStoreModalTitle.includes('新建')
              ? 'add_surplus_warehouse'
              : 'edit_surplus_warehouse',
          })
          if (
            !this.surplusStoreModalTitle.includes('新建') &&
            this.copySurStoreData.capacity != this.surplusStoreData.capacity
          ) {
            genFinalRecord(
              {
                key: this.surplusStoreData.name,
                operateArr: [
                  {
                    key: '库存上限修改',
                    dataArr: [
                      {
                        type: 'form',
                        current: { capacity: this.surplusStoreData.capacity },
                        ori: { capacity: this.copySurStoreData.capacity },
                        compareMsg: [
                          {
                            title: '',
                            keys: ['capacity'],
                          },
                        ], // 要比较的内容
                        formTextObj: {
                          capacity: '库存上限',
                        }, // key对应的意思
                        formValueObj: {}, // value对应的意思
                      },
                    ],
                  },
                ],
              },
              '余料库'
            )
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleDelSurS() {
      const params = {
        branch_nos: this.isBatchDel
          ? this.selectedSurplusStoreList.map((item) => item.branch_no)
          : [this.surplusStoreData.branch_no],
      }
      this.$delByToken('/surplus/branch_warehouses', params, (res) => {
        if (res.status == 1) {
          this.$message.success('删除成功')
          this.selectedSurplusStoreList = []
          this.isSelectAllStore = false
          if (
            this.page >
              Math.ceil(
                (this.totalSupplus - params.branch_nos.length) / this.pageSize
              ) &&
            this.page > 1
          ) {
            this.page = this.page - 1
          }
          this.getSurplusStoreList(true)
        } else {
          this.$message.error('删除失败')
        }
      })
      this.showDelSurplusStore = false
    },
    handleShowStorageSetting() {
      this.showStorageSetting = true
      this.oriCheckedStorageSetting = this.checkedStorageSetting
    },
    handleStorageSetting() {
      this.$token(
        '/surplus/branch_warehouses/setting',
        { sequential_warehousing: this.checkedStorageSetting },
        (res) => {
          if (res.status == 1) {
            this.checkedStorageSettingTemp = this.checkedStorageSetting
            this.$message.success('设置成功')
            genFinalRecord(
              {
                key: '',
                operateArr: [
                  {
                    key: '',
                    dataArr: [
                      {
                        type: 'form',
                        current: {
                          storeSetting: this.checkedStorageSetting
                            ? '按照仓库存放情况顺序入库'
                            : '存入余料清单',
                        },
                        ori: {
                          storeSetting: this.oriCheckedStorageSetting
                            ? '按照仓库存放情况顺序入库'
                            : '存入余料清单',
                        },
                        compareMsg: [
                          {
                            title: '',
                            keys: ['storeSetting'],
                          },
                        ], // 要比较的内容
                        formTextObj: {
                          storeSetting: '余料入库设置',
                        }, // key对应的意思
                        formValueObj: {}, // value对应的意思
                      },
                    ],
                  },
                ],
              },
              '余料库'
            )
          } else {
            // 如果设置失败，按钮不改变
            this.checkedStorageSetting = this.checkedStorageSettingTemp
            this.$message.error(res.msg)
          }
        }
      )
      this.showStorageSetting = false
    },
    // 全选余料库
    selectAllSurplusStore() {
      let status = true
      if (!this.isSelectAllStore) {
        status = false
      }
      for (let i = 0; i < this.surplusStoreArr.length; ++i) {
        this.surplusStoreArr[i].isSelected = status
      }
      this.selectedSurplusStoreList = this.surplusStoreArr.filter(
        (item) => item.isSelected
      )
      this.$forceUpdate()
    },
    handleClickSupplusStore(supplus) {
      this.currentClickSurplusStore = supplus
    },
    // 选择余料库处理
    handleSelectedSurplusStore() {
      let flag = true
      for (let i = 0; i < this.surplusStoreArr.length; ++i) {
        if (!this.surplusStoreArr[i].isSelected) {
          flag = false
          break
        }
      }
      if (flag) {
        this.isSelectAllStore = true
      } else {
        this.isSelectAllStore = false
      }
      this.selectedSurplusStoreList = this.surplusStoreArr.filter(
        (item) => item.isSelected
      )
    },
    // 获取余料库数据
    getSurplusStoreList(isDel, page) {
      if (this.storeKeyWord != '') {
        this.isFilterStatus = true
        this.page = 1
      }
      const params = {
        search_key: this.storeSearchType,
        search_val: this.storeKeyWord,
        page_no: page || this.page,
        page_size: this.pageSize,
      }
      this.$getByToken('/surplus/branch_warehouses', params, (res) => {
        if (res.status == 1) {
          this.surplusStoreArr = res.data.branch_warehouses
          this.totalSupplus = res.data.total
          if (isDel && this.checkedStorageSetting && res.data.total == 0) {
            this.checkedStorageSetting = false
            this.$token(
              '/surplus/branch_warehouses/setting',
              {
                sequential_warehousing: this.checkedStorageSetting,
              },
              (setRes) => {
                if (setRes.status == 1) {
                  genFinalRecord(
                    {
                      key: '',
                      operateArr: [
                        {
                          key: '',
                          dataArr: [
                            {
                              type: 'form',
                              current: {
                                storeSetting: '存入余料清单',
                              },
                              ori: {
                                storeSetting: '按照仓库存放情况顺序入库',
                              },
                              compareMsg: [
                                {
                                  title: '',
                                  keys: ['storeSetting'],
                                },
                              ], // 要比较的内容
                              formTextObj: {
                                storeSetting: '余料入库设置',
                              }, // key对应的意思
                              formValueObj: {}, // value对应的意思
                            },
                          ],
                        },
                      ],
                    },
                    '余料库'
                  )
                }
              }
            )
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 是否显示tooltip
    showTootip(value, surplus, prop) {
      let dom = this.$refs[`surplus_${surplus.branch_no}_${prop}`][0]
      if (dom) {
        if (dom.offsetWidth < dom.scrollWidth) {
          this.$set(surplus, `show${prop}`, true)
        } else {
          this.$set(surplus, `show${prop}`, false)
        }
        if (!value) {
          surplus[`show${prop}`] = false
        }
      }
    },
    // 切换每页显示条数
    onShowSizeChange(val, pageSize) {
      this.pageSize = pageSize
      this.getSurplusStoreList(false, val)
    },
    // 切换页码
    changeCurrentPage() {
      this.isSelectAllStore = false
      this.getSurplusStoreList()
    },
    // 页码输入限制
    pageInputCheck() {
      this.jumpPage = this.jumpPage.replace(/^(0+)|[^\d]+/g, '')
    },
    // 跳转至指定页
    jumpToPage() {
      if (this.jumpPage != '') {
        this.page = Number(this.jumpPage)
        this.getSurplusStoreList()
      }
    },
    // 新建/编辑输入框弹窗检测
    handleInputCheck(type) {
      if (type == 'capacity' && this.dealDecimal(type)) {
        this.inputAlert[type] = `${this.inputAlertMap[type]}只能输入整数!`
      } else if (
        this.surplusStoreData[type] === '' ||
        Number(this.surplusStoreData[type]) == 0
      ) {
        this.inputAlert[type] = `${this.inputAlertMap[type]}不能为空!`
      } else {
        this.inputAlert[type] = ''
      }
      this.surplusStoreData.name = this.surplusStoreData.name.slice(0, 20)
    },
    dealDecimal(type) {
      let reg = /[^\d]/
      let flag = false
      if (reg.test(this.surplusStoreData[type])) {
        flag = true
      }
      this.surplusStoreData[type] = this.surplusStoreData[type]
        .replace(/\./g, '')
        .slice(0, 6)
      return flag
    },
    // 判断输入是否为空
    checkInputIsBlank() {
      let ableSave = true
      Object.keys(this.surplusStoreData).forEach((key) => {
        if (
          (key == 'name' || key == 'capacity') &&
          (this.surplusStoreData[key] === '' ||
            Number(this.surplusStoreData[key]) == 0)
        ) {
          this.inputAlert[key] = `${this.inputAlertMap[key]}不能为空!`
          ableSave = false
        }
      })
      return ableSave
    },
    // 获取余料分仓设置
    handleGetSurWarSet() {
      this.$getByToken('/surplus/branch_warehouses/setting', {}, (res) => {
        if (res.status == 1) {
          this.checkedStorageSetting = res.data.sequential_warehousing
          this.checkedStorageSettingTemp = res.data.sequential_warehousing
        } else {
          this.$message.error('获取余料入库失败')
        }
      })
    },
    t(k) {
      return translate('surplusPage.' + k)
    },
    // 翻译余料库表格表头列
    updateTransSurplusTable() {
      this.surplusStoreColumns = this.surplusStoreColumns.map((item) => {
        if (!['select'].includes(item.key)) {
          return {
            ...item,
            title: translate(item.translateKey),
            // 自定义头部单元格
            customHeaderCell: () => ({
              attrs: {
                // 可以在这里添加额外的属性，比如 title
                title: translate(item.translateKey),
              },
            }),
          }
        } else {
          return item
        }
      })
    },
    updateTransEmpty() {
      if (this.totalSupplus == 0) {
        this.$nextTick(() => {
          const emptyDom = document.querySelector(
            '.surplus-store-table .ant-empty-description'
          )
          if (emptyDom) {
            if (!this.isFilterStatus) {
              emptyDom.innerHTML = `${this.$t('common.notDataTipByType', {})}
            <span
              id="supplus_store_table_add_btn"
              style="color: #18a8c7; cursor: pointer"
            >${this.$t('surplusPage.addSurplusStore')}</span>
          `
            } else {
              // 未查询到相关数据
              emptyDom.innerHTML = this.$t('surplusPage.noMatchSup')
            }
          }
          const addBtn = document.querySelector('#supplus_store_table_add_btn')
          if (addBtn) {
            addBtn.addEventListener('click', this.handleShowAddSurS)
          }
        })
      }
    },
  },
  mounted() {
    this.getSurplusStoreList()
    this.handleGetSurWarSet()
    this.updateTransSurplusTable()
    EventBus.$on(EventBusKey.TRANSLATE, this.updateTransSurplusTable)
    EventBus.$on(EventBusKey.TRANSLATE, this.updateTransEmpty)
  },
  unmounted() {
    EventBus.$off(EventBusKey.TRANSLATE, this.updateTransSurplusTable)
    EventBus.$off(EventBusKey.TRANSLATE, this.updateTransEmpty)
  },
}
</script>

<style scoped lang="less">
.surplus-store {
  .top-operation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
  }
  .left-operation {
    display: flex;
    align-items: center;
  }

  .supplus-btn {
    display: flex;
    align-items: center;
    width: 110px;
    height: 32px;

    .iconfont {
      margin-right: 3px;
    }
  }

  .new-supplus {
    margin-right: 8px;
    color: #fff;
    background: #18a8c7;
  }

  .delete-btn-hover {
    color: #333;

    &:hover {
      color: #18a8c7;
    }
  }
  .right-filter {
    display: flex;
    align-items: center;

    .ant-select {
      width: 160px;
      margin-right: 8px;
    }

    .ant-input-search {
      width: 330px;
      margin-right: 8px;

      /deep/ .ant-input-group-addon {
        box-sizing: border-box;
        padding: 0;

        .ant-select.ant-select-enabled {
          width: 98px !important;
          margin-right: 0;
          margin-left: 1px;
        }
      }
    }

    .search-btn {
      width: 84px;
      color: #fff;
      background: #18a8c7;
      border: none;
    }

    .reset-btn {
      width: 82px;
      color: #333;
      background: #fafafa;

      &:hover {
        color: #18a8c7;
      }
    }
  }

  /deep/.surplus-store-table {
    .ant-table {
      border: 1px solid #dcdcdc;
      border-bottom: none;
      .ant-table-placeholder {
        border-bottom: 1px solid #dcdcdc;
      }
    }
    .ant-table-thead > tr {
      height: 40px !important;
      > th {
        height: 40px;
        padding: 0;
        background: #f5f5f5;
      }
      > th[key='select'] {
        .ant-table-header-column {
          padding-left: 20px;
        }
      }
      > th[key='operation'] {
        .ant-table-header-column {
          padding-left: 30px;
        }
      }
    }
    .ant-table-tbody > tr {
      position: relative;
      height: 40px !important;
      > td {
        height: 40px;
        padding: 0;
        border-bottom-color: #dcdcdc;
        .table-operation-btn {
          color: #18a8c7;
          cursor: pointer;
        }
      }
    }
  }

  .supplus-pagination {
    display: flex;
    justify-content: space-between;
    width: 98.3%;
    min-width: 1440px;
    height: 64px;
    margin-top: 30px;
    padding: 16px;
    background: #f3f3f3;

    & > div {
      display: flex;
      align-items: center;
    }

    .jump-to-page {
      display: flex;
      align-items: center;

      .ant-input {
        width: 48px;
      }

      span:first-child {
        margin-right: 8px;
      }

      span:last-child {
        margin-left: 8px;
      }
    }
  }
}
</style>

<style lang="less">
.add-surStore-modal {
  .ant-modal {
    width: 600px !important;
    .ant-modal-body {
      .conatin {
        margin-left: 0;
      }
    }
  }
  .modal-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }
  .modal-label {
    width: 120px;
    color: red;
    text-align: right;
  }
  .modal-required {
    margin-right: 5px;
    color: #f25e4a;
  }
  .error-info {
    position: absolute;
    top: 30px;
    left: 175px;
    color: #fd3232;
    font-size: 12px;
  }
  .alert-input {
    /deep/.ant-select-selection {
      border-color: #fd3232;
    }

    /deep/.ant-input {
      border-color: #fd3232;
    }

    &.ant-input {
      border-color: #fd3232;
    }
  }
}
</style>
