<template>
  <div class="supplus-storage">
    <a-tabs
      v-model="activeKey"
      style="background: #fff"
      @change="handleChangeTab"
    >
      <a-tab-pane key="余料清单">
        <template #tab>
          <span id="supplus_storage_list">
            {{ t('surplusMaterial') }}
          </span>
        </template>
      </a-tab-pane>
      <a-tab-pane key="余料库" force-render>
        <template #tab>
          <span id="supplus_storage_store">
            {{ t('surplusStore') }}
          </span>
        </template></a-tab-pane
      >
    </a-tabs>
    <!-- 定制用户金标 -->
    <div
      :class="{
        'special-flag': isCustomUser,
      }"
      class="flag"
    >
      <!-- 定制功能/限时试用 -->
      {{
        isCustomUser
          ? $t('common.customFunction')
          : $t('common.limitedTimeTrial')
      }}
    </div>
    <div class="loading-box" v-if="showLoading">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 60px" spin />
      </a-spin>
    </div>
    <div class="supplus-content bg-white" v-show="!showAddNewSupplus">
      <div v-if="activeKey == '余料清单'" class="h100">
        <div class="top-operation pb20">
          <div class="left-operation">
            <a-button
              id="supplus_storage_add_btn"
              class="supplus-btn new-supplus"
              @click="addNewSupplusSide"
            >
              <span class="iconfont icon-add"></span>
              <span>
                <!-- 添加余料 -->
                {{ t('addSurplus') }}
              </span>
            </a-button>
            <a-popover
              placement="bottomLeft"
              trigger="click"
              overlayClassName="more-popover"
            >
              <template #content>
                <a-button
                  class="action-btn fs14 color-0 br4 no-select inline-block align-center mb8"
                >
                  <a-checkbox
                    v-model="isLessReserve"
                    @change="handleLessReserve"
                    id="supplusStorage_isLessReserve"
                  >
                    <span id="supplusStorage_isLessReserve_lable">
                      <!-- 启用余料不翻版加工 -->
                      <g-t-view
                        :text="$t('surplusPage.surplusNoRoll')"
                        width="130px"
                      ></g-t-view>
                    </span>
                  </a-checkbox>
                </a-button>
                <newContent :show="!isShowSurLockNew">
                  <a-button
                    class="action-btn surplus-lock-btn fs14 color-0 br4 no-select inline-block align-center"
                  >
                    <a-checkbox
                      v-model="isSurplusLock"
                      @change="handleChangeSurplusLock"
                      id="supplusStorage_surplusLock"
                    >
                      <span id="supplusStorage_surplusLock_label">
                        <!-- 启用余料锁定功能 -->
                        <g-t-view
                          :text="$t('surplusPage.enableSurplusLock')"
                          width="116px"
                        ></g-t-view>
                      </span>
                    </a-checkbox>
                  </a-button>
                  <a-tooltip placement="bottom">
                    <template slot="title">
                      {{ $t('surplusPage.enableSurLockTip') }}
                    </template>
                    <i
                      class="iconfont icon-warning absolute r5 t5"
                      style="color: #18a8c7"
                    ></i>
                  </a-tooltip>
                </newContent>
              </template>
              <newContent :show="!isShowSurLockNew">
                <a-button>
                  <span>
                    <!-- 更多按钮 -->
                    {{ $t('common.moreOperate') }}
                  </span>
                  <i class="iconfont icon-chevron-down1 ml8"></i>
                </a-button>
              </newContent>
            </a-popover>
            <a-button v-show="isSurplusLock" class="ml8" @click="handleRefresh">
              <span class="flex flex-cross--center">
                <i class="iconfont icon-Frame21" style="font-size: 18px"></i>
                <span>
                  <!-- 刷新按钮 -->
                  {{ $t('common.refresh') }}
                </span>
              </span>
            </a-button>
          </div>
          <div class="right-filter">
            <a-input-search
              v-model="keyWord"
              :placeholder="$t('common.inputPlaceholder')"
              @search="getSupplusList()"
              @change="handleChange"
              id="supplus_storage_search_input"
              allowClear
            >
              <a-select
                slot="addonBefore"
                v-model="searchType"
                style="width: 90px"
                id="supplus_storage_search_type_select"
              >
                <a-select-option
                  id="supplus_storage_search_type_select_name"
                  value="name"
                >
                  <!-- 余料名称 -->
                  {{ t('surplusName') }}
                </a-select-option>
                <a-select-option
                  id="supplus_storage_search_type_select_remark"
                  value="remark"
                >
                  <!-- 备注 -->
                  {{ $t('common.remark') }}
                </a-select-option>
              </a-select>
            </a-input-search>
          </div>
        </div>
        <div class="filter pv16 mb20">
          <div class="flex flex-wrap--wrap">
            <div class="filter-item">
              <span class="label">
                <!-- 余料形状 -->
                {{ t('surplusShape') }}：
              </span>
              <a-select
                id="supplus_storage_filter_shap_select"
                v-model="activeShape"
                style="width: 200px"
              >
                <a-select-option
                  id="supplus_storage_filter_shap_select_all"
                  value="全部"
                >
                  <!-- 全部 -->
                  {{ $t('common.all') }}
                </a-select-option>
                <a-select-option
                  id="supplus_storage_filter_shap_select_rect"
                  value="rect"
                >
                  <!-- 矩形 -->
                  {{ $t('common.rect') }}
                </a-select-option>
                <a-select-option
                  id="supplus_storage_filter_shap_select_L"
                  value="L"
                >
                  <!-- L形 -->
                  {{ $t('common.Ltype') }}
                </a-select-option>
              </a-select>
            </div>
            <div class="filter-item">
              <span class="label">
                <!-- 板材材质 -->
                {{ $t('common.plankMatCode') }}：
              </span>
              <a-select
                id="supplus_storage_filter_matcode_select"
                v-model="activeMatcode"
                style="width: 200px"
              >
                <a-select-option
                  v-for="(item, index) in ['全部', ...allMatcodeList]"
                  :key="index + 'matcode'"
                  :id="`supplus_storage_filter_matcode_select_${index}`"
                  :value="item"
                  >{{ index ? item : $t('common.all') }}</a-select-option
                >
              </a-select>
            </div>
            <div class="filter-item">
              <span class="label">
                <!-- 板材厚度 -->
                {{ $t('common.plankThick') }}：
              </span>
              <a-select
                id="supplus_storage_filter_thick_select"
                v-model="activeThick"
                style="width: 200px"
              >
                <a-select-option
                  v-for="(item, index) in ['全部', ...allThickList]"
                  :key="index + 'thick'"
                  :id="`supplus_storage_filter_thick_select_${index}`"
                  :value="item"
                  >{{
                    item == '全部' ? $t('common.all') : item + 'mm'
                  }}</a-select-option
                >
              </a-select>
            </div>
            <div class="filter-item">
              <span class="label">
                <!-- 板材颜色 -->
                {{ $t('common.plankColor') }}：
              </span>
              <a-select
                id="supplus_storage_filter_color_select"
                v-model="activeColor"
                style="width: 200px"
                showSearch
              >
                <a-select-option
                  v-for="(item, index) in [
                    { label: $t('common.all'), value: '全部' },
                    ...this.allColorList,
                  ]"
                  :key="index + 'color'"
                  :id="`supplus_storage_filter_color_select_${index}`"
                  :value="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </div>
            <div class="filter-item">
              <span class="label">
                <!-- 余料库 -->
                {{ t('surplusStore') }}：
              </span>
              <a-select
                id="supplus_storage_filter_store_select"
                v-model="activeSurplusStore"
                style="width: 200px"
                showSearch
              >
                <a-select-option
                  v-for="(item, index) in [
                    { label: $t('common.all'), value: '全部' },
                    ...this.surplusStoreList,
                  ]"
                  :key="index + 'store'"
                  :id="`supplus_storage_filter_store_select_${index}`"
                  :value="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </div>
            <div class="filter-item" v-show="isSurplusLock">
              <span class="label">
                <!-- 锁定状态 -->
                {{ t('lockStatus') }}：
              </span>
              <a-select
                id="supplus_storage_filter_lock_select"
                v-model="activeLockState"
                style="width: 200px"
              >
                <a-select-option
                  v-for="(item, index) in [
                    { label: $t('common.all'), value: '全部' },
                    ...lockStateList,
                  ]"
                  :key="index + 'lock'"
                  :id="`supplus_storage_filter_lock_select_${index}`"
                  :value="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </div>
            <div class="filter-item">
              <span class="label">
                <!-- 添加时间 -->
                {{ $t('common.addTime') }}：
              </span>
              <el-date-picker
                v-model="activeTime"
                type="daterange"
                align="right"
                unlink-panels
                :range-separator="$t('common.to')"
                :start-placeholder="$t('common.startDate')"
                :end-placeholder="$t('common.endDate')"
                :picker-options="pickerOptions"
                value-format="yyyy/MM/dd"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="filter-btn">
            <a-button
              class="mr8"
              type="primary"
              @click="getSupplusList()"
              id="supplus_storage_filter_btn"
            >
              <!-- 筛选 -->
              {{ ct('filter') }}
            </a-button>
            <a-button @click="resetClassify" id="supplus_storage_reset_btn">
              <!-- 重置 -->
              {{ ct('reset') }}
            </a-button>
          </div>
        </div>
        <a-table
          :columns="supplusColumn"
          :data-source="supplusList"
          row-key="id"
          :scroll="{
            x: '100%',
            y: 'calc(100vh - 64px - 40px - 160px - 20px - 52px - 20px - 170px)',
          }"
          :pagination="false"
          :customRow="handleClickTableRow"
          class="surplus-table"
        >
          <template slot="selectTitle">
            <a-checkbox
              v-model="isSelectAll"
              id="supplus_storage_list_all_checkbox"
              @change="selectAllSurplus()"
            ></a-checkbox>
          </template>
          <template slot="select" slot-scope="text, record, index">
            <GCopyLine
              v-if="record.id === currentClickSurplus.id"
              class="copy-line"
              :showText="ct('clickCopy', { type: ct('currentLine') })"
              :id="`supplus_storage_table_${index}_copy_btn`"
              @copyClick="copyLine(record)"
            />
            <a-checkbox
              v-model="record.isSelected"
              class="ml20"
              :id="`supplus_storage_table_${index}_select_checkbox`"
              @change="selectedSurplus(record)"
            ></a-checkbox>
          </template>
          <template slot="surplusType" slot-scope="text, record, index">
            <!-- 形状 -->
            <g-t-view
              style="padding-left: 10px"
              width="100%"
              :id="`supplus_storage_table_${index}_surplusType_checkbox`"
              :text="$t(record.surplusType)"
            >
            </g-t-view>
          </template>
          <template slot="type" slot-scope="text, record">
            {{ record.type?.replace('高光_', '') }}
          </template>
          <template slot="is_high_gloss_plank" slot-scope="text, record, index">
            <a-select
              v-model="record.is_high_gloss_plank"
              size="small"
              :id="`supplus_storage_table_${index}_highGlossPlank_select`"
              @change="onHighPlankChange(record)"
              placeholder="请选择是否是高光板"
            >
              <a-select-option
                v-for="(item, i) in [
                  { value: 0, label: '非高光板' },
                  { value: 1, label: '高光板' },
                ]"
                :key="i"
                :value="item.value"
                :id="`supplus_storage_table_${index}_highGlossPlank_select_${i}`"
                >{{ item.label }}</a-select-option
              >
            </a-select>
          </template>
          <template slot="lock_status" slot-scope="text, record">
            {{ record.lock_status ? '已锁定' : '未锁定' }}
          </template>
          <template slot="lock_order" slot-scope="text, record">
            <span :title="record.lock_order">{{
              record.lock_order ? record.lock_order : '-'
            }}</span>
          </template>
          <template slot="operation" slot-scope="text, record, index">
            <div
              style="
                display: flex;
                justify-content: space-between;
                padding-right: 8px;
              "
            >
              <span
                :id="`supplus_storage_table_${index}_edit_btn`"
                class="table-operation-btn ml10"
                @click="showEditSurplus(record)"
              >
                <!-- 编辑 -->
                <g-t-view :text="ct('edit')" width="28px"></g-t-view>
              </span>
              <a-popover
                trigger="click"
                placement="bottom"
                @visibleChange="(value) => showSupplusShape(value, record)"
                destroyTooltipOnHide
                arrowPointAtCenter
                :getPopupContainer="getPopupContainer"
              >
                <template slot="content">
                  <drawSupplus
                    :canvasKey="false"
                    :size="drawObj"
                    :drawScale="drawScale"
                  ></drawSupplus>
                </template>
                <span
                  :id="`supplus_storage_table_${index}_detail_btn`"
                  class="table-operation-btn"
                >
                  <!-- 详情 -->
                  <g-t-view :text="ct('detail')" width="28px"></g-t-view>
                </span>
              </a-popover>
              <a-popover
                placement="bottom"
                overlayClassName="more-operate-popover"
              >
                <template #content>
                  <p
                    style="color: #18a8c7"
                    class="cursor-pointer"
                    @click="handleShowTransSurplus(record)"
                    :id="`supplus_storage_table_${index}_tran_btn`"
                  >
                    <!-- 转移 -->
                    {{ ct('transferByType', {}) }}
                  </p>
                  <p
                    style="color: #18a8c7"
                    class="cursor-pointer"
                    @click="showDeleteDialog(record)"
                    :id="`supplus_storage_table_${index}_delete_btn`"
                  >
                    <!-- 删除 -->
                    {{ ct('delete') }}
                  </p>
                  <p
                    style="color: #18a8c7"
                    class="cursor-pointer mb0"
                    @click="handleLock(record)"
                    v-show="isSurplusLock"
                    :id="`supplus_storage_table_${index}_lock_btn`"
                  >
                    <!-- 锁定、解锁 -->
                    <g-t-view
                      :text="
                        record.lock_status == 1 ? ct('unlock') : ct('lock')
                      "
                      width="56px"
                    ></g-t-view>
                  </p>
                </template>
                <span class="table-operation-btn mr0">
                  <!-- 更多 -->
                  <g-t-view :text="ct('moreOperate')" width="56px"></g-t-view>
                </span>
              </a-popover>
            </div>
          </template>
        </a-table>
        <div class="supplus-pagination absolute b5">
          <div>
            <span class="color-o-6 mr15">
              <!-- 已选 {{selectedSurplusList.length}} 条数据 -->
              {{
                ct('chooseAmountData', {
                  number: selectedSurplusList.length + '',
                })
              }}
            </span>
            <a-button
              @click="showDeleteDialog()"
              :disabled="notAbleDelete"
              id="supplus_storage_delete-btn"
            >
              {{ $t('common.batchDelete') }}
            </a-button>
            <a-button
              @click="handleShowTransSurplus()"
              :disabled="notAbleDelete"
              id="supplus_storage_trans-btn"
              class="ml8"
            >
              <!-- 批量转移 -->
              {{ t('batchTransfer') }}
            </a-button>
            <a-popover
              placement="top"
              overlayClassName="batch-lock-popover"
              trigger="click"
              :visible="showBatchLockPop"
            >
              <template #content>
                <p
                  class="cursor-pointer"
                  @click="handleBatchLock(1)"
                  id="supplus_storage_lock-btn"
                >
                  <!-- 余料锁定 -->
                  <g-t-view :text="ct('surplusLock')" width="90px"></g-t-view>
                </p>
                <p
                  class="cursor-pointer mb0"
                  @click="handleBatchLock(0)"
                  id="supplus_storage_un_lock-btn"
                >
                  <!-- 余料解锁 -->
                  <g-t-view :text="ct('surplusUnlock')" width="90px"></g-t-view>
                </p>
              </template>
              <a-button
                :disabled="notAbleDelete"
                id="supplus_storage_batch_lock-btn"
                class="ml8"
                v-show="isSurplusLock"
                @click="showBatchLockPop = true"
                :title="$t('common.batchLock')"
              >
                <!-- 批量锁定/解锁 -->
                <g-t-view :text="ct('batchLock')" width="90px"></g-t-view>
              </a-button>
            </a-popover>
          </div>
          <div>
            <a-pagination
              v-model="page"
              :page-size-options="pageSizeOptions"
              :total="totalSupplus"
              show-size-changer
              :page-size="pageSize"
              @showSizeChange="onShowSizeChange"
              @change="changeCurrentPage"
              :show-total="
                () =>
                  ct('paginationTotalTip', {
                    current: (page - 1) * pageSize + 1,
                    page: page * pageSize,
                    total: totalSupplus,
                  })
              "
            >
              <template slot="buildOptionText" slot-scope="props">
                <span v-if="props.value != totalSupplus"
                  >{{ props.value }}{{ ct('strip') }}/{{ ct('page') }}</span
                >
                <span v-if="props.value == totalSupplus">{{ ct('all') }}</span>
              </template>
            </a-pagination>
            <div class="jump-to-page">
              <span>
                <!-- 跳至 -->
                {{ t('jumpTo') }}
              </span>
              <a-input
                @pressEnter="jumpToPage"
                @input="pageInputCheck"
                v-model="jumpPage"
                id="supplus_storage_pagination_input"
              ></a-input>
              <span>{{ ct('page') }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 余料库页面 -->
      <div v-else>
        <surplusStore @onGoDetail="handleGoDetail" />
      </div>
    </div>
    <!--    新建材质/新建颜色-->
    <div
      class="new-classify-dialog"
      v-if="isShowNewClassify"
      @click="closeNewClassify"
      id="supplus_storage_new_btn"
      style="z-index: 9999"
    >
      <div class="new-classify-content" @click.stop>
        <div class="new-classify-title">
          {{
            newClassifyType == 'matcode'
              ? translateLang('surplusPage.newMatcode')
              : translateLang('surplusPage.newColor')
          }}
        </div>
        <a-input
          id="supplus_storage_new_class_input"
          v-model="newClassify"
          class="new-classify"
        ></a-input>
        <div class="new-classify-btns">
          <div>
            <a-button
              id="supplus_storage_new_cancel_btn"
              @click="closeNewClassify"
              >{{ $t('common.cancel') }}</a-button
            >
            <a-button
              id="supplus_storage_new_confirm_btn"
              class="confirm-btn"
              @click="saveNewClassify"
              >{{ $t('common.confirm') }}</a-button
            >
          </div>
        </div>
      </div>
    </div>

    <!--  编辑侧边栏-->

    <el-drawer
      :visible.sync="showEditDrawer"
      direction="rtl"
      @close="closeEditDrawer"
      custom-class="surplus-new-plank"
      :size="500"
      :modal-append-to-body="false"
      :show-close="false"
    >
      <template slot="title">
        <div class="drawer-title-box">
          <span style="color: #333; font-size: 18px">{{
            $t('surplusPage.editSurplus')
          }}</span>
          <span
            id="supplus_storage_edit_close_btn"
            class="iconfont icon-close"
            @click="closeEditDrawer"
          ></span>
        </div>
      </template>
      <div class="edit-plank-info">
        <div class="plank-info-item">
          <div class="title-box">
            <span class="import">*</span>
            <span class="title">{{ $t('surplusPage.surplusName') }}：</span>
          </div>
          <a-input
            :maxLength="11"
            v-model="newSupplus.name"
            :placeholder="translateLang('common.inputPlaceholder')"
            :class="inputAlert.nameInputAlert ? 'alert-input' : ''"
            @input="inputCheck('name')"
            @blur="inputCheck('name')"
            id="supplus_storage_edit_name_input"
          >
            <span class="plank-form-limit" slot="suffix"
              >{{ newSupplus.name.length }}/10</span
            >
          </a-input>
          <transition name="errorInfo">
            <span v-show="inputAlert.nameInputAlert" class="error-info">
              {{ inputAlert.nameInputAlert }}
            </span>
          </transition>
        </div>
        <div class="plank-info-item">
          <div class="title-box">
            <span class="title"
              >{{ $t('materialPage.surplusHead.shape') }}：</span
            >
          </div>
          <a-input
            disabled
            :value="
              currentSurplus.shape == 1
                ? translateLang('common.Ltype')
                : translateLang('common.rect')
            "
          ></a-input>
        </div>
        <div class="plank-info-item">
          <div class="title-box">
            <span class="import">*</span>
            <span class="title">{{ $t('common.plankHeight') }}：</span>
          </div>
          <a-input
            v-model="newSupplus.long"
            :placeholder="translateLang('common.inputPlaceholder')"
            :class="inputAlert.longInputAlert ? 'alert-input' : ''"
            @input="inputCheck('long')"
            @blur="inputCheck('long')"
            id="supplus_storage_edit_long_input"
          >
            <span slot="addonAfter" class="plank-form-after">mm</span>
          </a-input>
          <transition name="errorInfo">
            <span v-show="inputAlert.longInputAlert" class="error-info">
              {{ inputAlert.longInputAlert }}
            </span>
          </transition>
        </div>
        <div class="plank-info-item" v-if="selectedShape == 'L'">
          <div class="title-box">
            <span class="import">*</span>
            <span class="title">{{ $t('common.shortLong') }}：</span>
          </div>
          <a-input
            v-model="newSupplus.min_long"
            :placeholder="translateLang('common.inputPlaceholder')"
            :class="inputAlert.minLongInputAlert ? 'alert-input' : ''"
            @input="inputCheck('min_long')"
            @blur="inputCheck('min_long')"
            id="supplus_storage_edit_min_long_input"
          >
            <span slot="addonAfter" class="plank-form-after">mm</span>
          </a-input>
          <transition name="errorInfo">
            <span v-show="inputAlert.minLongInputAlert" class="error-info">
              {{ inputAlert.minLongInputAlert }}
            </span>
          </transition>
        </div>
        <div class="plank-info-item">
          <div class="title-box">
            <span class="import">*</span>
            <span class="title">{{ $t('common.plankWidth') }}：</span>
          </div>
          <a-input
            v-model="newSupplus.width"
            :placeholder="translateLang('common.inputPlaceholder')"
            :class="inputAlert.widthInputAlert ? 'alert-input' : ''"
            @input="inputCheck('width')"
            @blur="inputCheck('width')"
            id="supplus_storage_edit_width_input"
          >
            <span slot="addonAfter" class="plank-form-after">mm</span>
          </a-input>
          <transition name="errorInfo">
            <span v-show="inputAlert.widthInputAlert" class="error-info">
              {{ inputAlert.widthInputAlert }}
            </span>
          </transition>
        </div>
        <div class="plank-info-item" v-if="selectedShape == 'L'">
          <div class="title-box">
            <span class="import">*</span>
            <span class="title">{{ $t('common.shortWidth') }}：</span>
          </div>
          <a-input
            v-model="newSupplus.min_width"
            :placeholder="translateLang('common.inputPlaceholder')"
            :class="inputAlert.minWidthInputAlert ? 'alert-input' : ''"
            @input="inputCheck('min_width')"
            @blur="inputCheck('min_width')"
            id="supplus_storage_edit_min_width_input"
          >
            <span slot="addonAfter" class="plank-form-after">mm</span>
          </a-input>
          <transition name="errorInfo">
            <span v-show="inputAlert.minWidthInputAlert" class="error-info">
              {{ inputAlert.minWidthInputAlert }}
            </span>
          </transition>
        </div>
        <div class="plank-info-item">
          <div class="title-box">
            <span class="import">*</span>
            <span class="title">{{ $t('common.plankThick') }}：</span>
          </div>
          <a-input
            v-model="newSupplus.thick"
            :placeholder="translateLang('common.inputPlaceholder')"
            :class="inputAlert.thickInputAlert ? 'alert-input' : ''"
            @input="inputCheck('thick')"
            @blur="inputCheck('thick')"
            id="supplus_storage_edit_thick_input"
          >
            <span slot="addonAfter" class="plank-form-after">mm</span>
          </a-input>
          <transition name="errorInfo">
            <span v-show="inputAlert.thickInputAlert" class="error-info">
              {{ inputAlert.thickInputAlert }}
            </span>
          </transition>
        </div>
        <div class="plank-info-item">
          <div class="title-box">
            <span class="import">*</span>
            <span class="title">{{ $t('common.plankMatCode') }}：</span>
          </div>
          <!-- <a-input
            v-model="newSupplus.type"
            v-if="isThinkerxMaterial"
            @blur="inputCheck('type')"
            @input="inputCheck('type')"
            :class="inputAlert.typeInputAlert ? 'alert-input' : ''"
            style="height: 32px;"
            id="supplus_storage_edit_type_input"
          ></a-input> -->
          <a-select
            show-search
            allowClear
            :placeholder="translateLang('common.inputPlaceholder')"
            option-filter-prop="children"
            style="width: 368px; height: 32px"
            :filter-option="filterOption"
            v-model="newSupplus.type"
            :class="inputAlert.typeInputAlert ? 'alert-input' : ''"
            @blur="inputCheck('type')"
            @change="handleSelectMatCode"
            @keydown.native.enter="handleEnterMatCode"
            dropdownClassName="supplus-storage-select"
            id="supplus_storage_edit_type_select"
          >
            <a-select-option
              v-for="(item, index) in matcodeList.filter((v) => v != '全部')"
              :key="'type' + index"
              :value="item + '@_@' + index"
              :id="`supplus_storage_edit_type_select_${index}`"
              >{{ item }}</a-select-option
            >
          </a-select>
          <transition name="errorInfo">
            <span v-show="inputAlert.typeInputAlert" class="error-info">
              {{ inputAlert.typeInputAlert }}
            </span>
          </transition>
        </div>
        <div class="plank-info-item">
          <div class="title-box">
            <span class="import">*</span>
            <span class="title">{{ $t('common.plankColor') }}：</span>
          </div>
          <el-cascader
            v-loading="isLoadingColors"
            id="supplus_material_edit_color_input"
            :class="{ 'error-border': inputAlert.colorInputAlert }"
            size="small"
            v-model="newPlankInfoTexture"
            :options="materialColorOptions"
            @change="handleSelectTexture"
            @keydown.enter.native="handleEnterColor"
            @keyup.delete.native="handleDeleteColorSearch('edit')"
            @blur="inputCheck('color')"
            ref="editColorCascaderRef"
            :before-filter="onBeforeFilter"
            :show-all-levels="false"
            filterable
            clearable
            :placeholder="translateLang('surplusPage.autoCreateColor')"
          />

          <transition name="errorInfo">
            <span v-show="inputAlert.colorInputAlert" class="error-info">
              {{ inputAlert.colorInputAlert }}
            </span>
          </transition>
        </div>
        <div class="plank-info-item">
          <div class="title-box">
            <span class="import">*</span>
            <span class="title">{{ $t('common.count') }}：</span>
          </div>
          <a-input
            v-model="newSupplus.amount"
            :placeholder="translateLang('common.inputPlaceholder')"
            :class="inputAlert.amountInputAlert ? 'alert-input' : ''"
            @input="inputCheck('amount')"
            @blur="inputCheck('amount')"
            id="supplus_storage_edit_amount_input"
          ></a-input>
          <transition name="errorInfo">
            <span v-show="inputAlert.amountInputAlert" class="error-info">
              {{ inputAlert.amountInputAlert }}
            </span>
          </transition>
        </div>
        <!-- 高光板 -->
        <div class="plank-info-item">
          <div class="title-box">
            <span class="import">*</span>
            <span class="title">{{ $t('common.heighGlossPlank') }}：</span>
          </div>
          <el-select
            style="width: 368px"
            v-model="newSupplus.is_high_gloss_plank"
            size="small"
            :placeholder="translateLang('common.isHeightGloss')"
            id="supplus_storage_edit_gloss_select"
          >
            <el-option
              v-for="(item, index) in [
                { value: 0, label: 'common.notHeighGlossPlank' },
                { value: 1, label: 'common.heighGlossPlank' },
              ]"
              :key="index"
              :value="item.value"
              :label="translateLang(item.label)"
              :id="`supplus_storage_edit_gloss_select_${index}`"
            ></el-option>
          </el-select>
        </div>
        <div class="plank-info-item">
          <div class="title-box">
            <span class="title">
              <!-- 所在余料库 -->
              {{ t('branchName') }}：
            </span>
          </div>
          <el-select
            style="width: 368px"
            v-model="newSupplus.branch_no"
            size="small"
            placeholder="请选择所在余料库"
            id="supplus_storage_edit_branch_name_select"
            clearable
          >
            <el-option
              v-for="(item, index) in surplusStoreList"
              :key="index"
              :value="item.value"
              :label="item.label"
              :id="`supplus_storage_edit_branch_name_select_${index}`"
            ></el-option>
          </el-select>
        </div>
        <div class="plank-info-item" v-show="isSurplusLock">
          <div class="title-box">
            <span class="title">
              <!-- 锁定状态 -->
              {{ t('lockStatus') }}：
            </span>
          </div>
          <el-select
            style="width: 368px"
            v-model="newSupplus.lock_status"
            size="small"
            placeholder="请选择锁定状态"
            id="supplus_storage_edit_lock_state_select"
            clearable
          >
            <el-option
              v-for="(item, index) in lockStateList"
              :key="index"
              :value="item.value"
              :label="item.label"
              :id="`supplus_storage_edit_branch_name_select_${index}`"
            ></el-option>
          </el-select>
        </div>
        <div
          class="plank-info-item"
          v-show="
            isSurplusLock &&
            newSupplus.lock_order &&
            newSupplus.lock_status == 1
          "
        >
          <div class="title-box">
            <span class="title">
              <!-- 锁定订单： -->
              {{ t('lockOrder') }}：
            </span>
          </div>
          <a-input
            v-model="newSupplus.lock_order"
            :title="newSupplus.lock_order"
            :disabled="true"
          />
        </div>
        <div class="plank-info-item remark-form" v-if="!newSupplus.fromGg">
          <div class="title-box">
            <span class="title"
              >{{ $t('materialPage.surplusHead.remark') }}：</span
            >
          </div>
          <a-textarea
            style="height: 88px; resize: none"
            v-model="newSupplus.remark"
            @input="inputCheck('remark')"
            id="supplus_storage_edit_remark_textarea"
          ></a-textarea>
          <transition name="errorInfo">
            <span
              v-show="inputAlert.remarkInputAlert"
              class="error-info remark-info"
              >{{ inputAlert.remarkInputAlert }}</span
            >
          </transition>
        </div>
        <div class="edit-bottom-operation">
          <div>
            <a-button
              id="supplus_storage_edit_cancel_btn"
              @click="closeEditDrawer"
              >{{ $t('common.cancel') }}</a-button
            >
            <a-button
              id="supplus_storage_edit_confirm_btn"
              @click="confirmEditSurplus"
              >{{ $t('common.confirm') }}</a-button
            >
          </div>
        </div>
      </div>
    </el-drawer>

    <!--    侧边栏添加余料-->

    <el-drawer
      :title="translateLang('surplusPage.addSurplus')"
      :visible.sync="addSupplusDrawer"
      append-to-body
      size="550px"
      :with-header="false"
      @close="handleCloseDrawer"
    >
      <div class="new-supllus-dialog">
        <div style="padding: 20px">
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              height: 50px;
            "
          >
            <div
              :class="[
                selectedShape == 'rect' ? 'active-shape-side' : '',
                'select-shape-side',
              ]"
              @click="changeSelectedShape('rect')"
              id="supplus_storage_add_shape_rect_btn"
            >
              <span>{{ $t('surplusPage.rectSurplus') }}</span>
              <span class="iconfont icon-right"></span>
            </div>
            <div
              :class="[
                selectedShape == 'L' ? 'active-shape-side' : '',
                'select-shape-side',
              ]"
              @click="changeSelectedShape('L')"
              id="supplus_storage_add_shape_L_btn"
            >
              <span>{{ $t('surplusPage.LTypeSurplus') }}</span>
              <span class="iconfont icon-right"></span>
            </div>
          </div>

          <!-- 缩放比例 -->
          <div class="plank-img">
            <div style="margin-left: 28%">
              <drawSupplus
                :canvasKey="selectedShape"
                :size="drawObj"
                :drawScale="2.5"
                isNewSurplus
              ></drawSupplus>
            </div>
          </div>
          <div class="right-supplus-form">
            <div class="supplus-info-img">
              <div class="plank-info">
                <div
                  class="plank-info-item"
                  style="display: flex; flex-direction: column"
                >
                  <div class="base-info">
                    {{ $t('materialPage.paibanSetting.baseInfo') }}
                  </div>
                  <el-row>
                    <el-col :span="5">
                      <div style="float: right">
                        <span style="color: red">*</span>
                        <span style="line-height: 30px"
                          >{{ $t('surplusPage.surplusName') }}：</span
                        >
                      </div>
                    </el-col>
                    <el-col :span="19">
                      <a-input
                        :maxLength="11"
                        v-model="newSupplus.name"
                        :placeholder="translateLang('common.inputPlaceholder')"
                        :class="inputAlert.nameInputAlert ? 'alert-input' : ''"
                        @input="inputCheck('name')"
                        @blur="inputCheck('name')"
                        id="supplus_storage_add_name_input"
                      >
                        <span class="plank-form-limit" slot="suffix"
                          >{{ newSupplus.name.length }}/10</span
                        >
                      </a-input>
                      <transition name="errorInfo">
                        <span
                          v-show="inputAlert.nameInputAlert"
                          class="error-info"
                          >{{ inputAlert.nameInputAlert }}</span
                        >
                      </transition>
                    </el-col>
                  </el-row>
                </div>
                <div class="plank-info-item">
                  <el-row>
                    <el-col :span="5">
                      <div style="float: right">
                        <span style="color: red">*</span>
                        <span style="line-height: 30px"
                          >{{ $t('common.plankHeight') }}：</span
                        >
                      </div>
                    </el-col>
                    <el-col :span="19">
                      <a-input
                        v-model="newSupplus.long"
                        :placeholder="translateLang('common.inputPlaceholder')"
                        :class="inputAlert.longInputAlert ? 'alert-input' : ''"
                        @input="inputCheck('long')"
                        @blur="inputCheck('long')"
                        id="supplus_storage_add_long_input"
                      >
                        <span slot="addonAfter" class="plank-form-after"
                          >mm</span
                        >
                      </a-input>
                      <transition name="errorInfo">
                        <span
                          v-show="inputAlert.longInputAlert"
                          class="error-info"
                          >{{ inputAlert.longInputAlert }}</span
                        >
                      </transition>
                    </el-col>
                  </el-row>
                </div>

                <div class="plank-info-item" v-if="selectedShape == 'L'">
                  <el-row>
                    <el-col :span="5">
                      <div style="float: right">
                        <span style="color: red">*</span>
                        <span style="line-height: 30px"
                          >{{ $t('common.shortLong') }}：</span
                        >
                      </div>
                    </el-col>
                    <el-col :span="19">
                      <a-input
                        v-model="newSupplus.min_long"
                        :placeholder="translateLang('common.inputPlaceholder')"
                        :class="
                          inputAlert.minLongInputAlert ? 'alert-input' : ''
                        "
                        @input="inputCheck('min_long')"
                        @blur="inputCheck('min_long')"
                        id="supplus_storage_add_min_long_input"
                      >
                        <span slot="addonAfter" class="plank-form-after"
                          >mm</span
                        >
                      </a-input>
                      <transition name="errorInfo">
                        <span
                          v-show="inputAlert.minLongInputAlert"
                          class="error-info"
                          >{{ inputAlert.minLongInputAlert }}</span
                        >
                      </transition>
                    </el-col>
                  </el-row>
                </div>

                <div class="plank-info-item">
                  <el-row style="margin-top: 20px">
                    <el-col :span="5">
                      <div style="float: right">
                        <span style="color: red">*</span>
                        <span style="line-height: 30px"
                          >{{ $t('common.plankWidth') }}：</span
                        >
                      </div>
                    </el-col>
                    <el-col :span="19">
                      <a-input
                        v-model="newSupplus.width"
                        :placeholder="translateLang('common.inputPlaceholder')"
                        :class="inputAlert.widthInputAlert ? 'alert-input' : ''"
                        @input="inputCheck('width')"
                        @blur="inputCheck('width')"
                        id="supplus_storage_add_width_input"
                      >
                        <span slot="addonAfter" class="plank-form-after"
                          >mm</span
                        >
                      </a-input>
                      <transition name="errorInfo">
                        <span
                          v-show="inputAlert.widthInputAlert"
                          class="error-info"
                          >{{ inputAlert.widthInputAlert }}</span
                        >
                      </transition>
                    </el-col>
                  </el-row>
                </div>
                <div class="plank-info-item" v-if="selectedShape == 'L'">
                  <el-row>
                    <el-col :span="5">
                      <div style="float: right">
                        <span style="color: red">*</span>
                        <span style="line-height: 30px"
                          >{{ $t('common.shortWidth') }}：</span
                        >
                      </div>
                    </el-col>
                    <el-col :span="19">
                      <a-input
                        v-model="newSupplus.min_width"
                        :placeholder="translateLang('common.inputPlaceholder')"
                        :class="
                          inputAlert.minWidthInputAlert ? 'alert-input' : ''
                        "
                        @input="inputCheck('min_width')"
                        @blur="inputCheck('min_width')"
                        id="supplus_storage_add_min_width_input"
                      >
                        <span slot="addonAfter" class="plank-form-after"
                          >mm</span
                        >
                      </a-input>
                      <transition name="errorInfo">
                        <span
                          v-show="inputAlert.minWidthInputAlert"
                          class="error-info"
                          >{{ inputAlert.minWidthInputAlert }}</span
                        >
                      </transition>
                    </el-col>
                  </el-row>
                </div>
                <div class="plank-info-item">
                  <el-row>
                    <el-col :span="5">
                      <div style="float: right">
                        <span style="color: red">*</span>
                        <span style="line-height: 30px"
                          >{{ $t('common.plankThick') }}：</span
                        >
                      </div>
                    </el-col>
                    <el-col :span="19">
                      <a-input
                        v-model="newSupplus.thick"
                        :placeholder="translateLang('common.inputPlaceholder')"
                        :class="inputAlert.thickInputAlert ? 'alert-input' : ''"
                        @input="inputCheck('thick')"
                        @blur="inputCheck('thick')"
                        id="supplus_storage_add_thick_input"
                      >
                        <span slot="addonAfter" class="plank-form-after"
                          >mm</span
                        >
                      </a-input>
                      <transition name="errorInfo">
                        <span
                          v-show="inputAlert.thickInputAlert"
                          class="error-info"
                          >{{ inputAlert.thickInputAlert }}</span
                        >
                      </transition>
                    </el-col>
                  </el-row>
                </div>

                <div class="plank-info-item">
                  <el-row>
                    <el-col :span="5">
                      <div style="float: right">
                        <span style="color: red">*</span>
                        <span style="line-height: 30px"
                          >{{ $t('common.plankMatCode') }}：</span
                        >
                      </div>
                    </el-col>
                    <el-col :span="19">
                      <!-- <a-input
                        v-model="newSupplus.type"
                        v-if="isThinkerxMaterial"
                        @blur="inputCheck('type')"
                        @input="inputCheck('type')"
                        id="supplus_storage_add_type_input"
                        :class="inputAlert.typeInputAlert ? 'alert-input' : ''"
                      ></a-input> -->

                      <el-select
                        v-model="newSupplus.type"
                        :placeholder="translateLang('common.inputPlaceholder')"
                        filterable
                        clearable
                        size="medium"
                        show-search
                        option-filter-prop="children"
                        style="width: 318px; height: 32px"
                        :filter-option="filterOption"
                        :class="inputAlert.typeInputAlert ? 'alert-input' : ''"
                        @change="handleSelectMatCode"
                        @blur="inputCheck('type')"
                        @keydown.native.enter="handleEnterMatCode"
                        id="supplus_storage_add_type_select"
                        @visible-change="
                          (visibleEv) =>
                            colorOrTypeSelectEnter(visibleEv, 'type')
                        "
                        ref="type"
                      >
                        <el-option
                          v-for="(item, index) in matcodeList.filter(
                            (v) => v != '全部'
                          )"
                          :key="'type' + index"
                          :value="item"
                          :id="`supplus_storage_add_type_select_${index}`"
                          >{{ item }}</el-option
                        >
                      </el-select>
                      <transition name="errorInfo">
                        <span
                          v-show="inputAlert.typeInputAlert"
                          class="error-info"
                          >{{ inputAlert.typeInputAlert }}</span
                        >
                      </transition>
                      <div
                        class="newSideAdd"
                        @click="addNewMatcode"
                        id="supplus_storage_add_matcode_btn"
                        v-if="!isThinkerxMaterial"
                      >
                        <span>{{ $t('surplusPage.newMatcode') }}</span>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div class="plank-info-item">
                  <el-row>
                    <el-col :span="5">
                      <div class="title-box" style="float: right">
                        <span style="color: red">*</span>
                        <span style="line-height: 30px"
                          >{{ $t('common.plankColor') }}：</span
                        >
                      </div>
                    </el-col>
                    <el-col :span="19">
                      <!-- <a-input
                        v-model="newSupplus.color"
                        v-if="isThinkerxMaterial"
                        @blur="inputCheck('color')"
                        @input="inputCheck('color')"
                        id="supplus_storage_add_color_input"
                        :class="inputAlert.colorInputAlert ? 'alert-input' : ''"
                      ></a-input> -->

                      <el-cascader
                        v-loading="isLoadingColors"
                        id="supplus_material_color_input"
                        :class="{ 'error-border': inputAlert.colorInputAlert }"
                        size="small"
                        v-model="newPlankInfoTexture"
                        :options="materialColorOptions"
                        @change="handleSelectTexture"
                        @keydown.enter.native="handleEnterColor"
                        @keyup.delete.native="handleDeleteColorSearch"
                        @blur="inputCheck('color')"
                        :before-filter="onBeforeFilter"
                        :show-all-levels="false"
                        ref="addColorCascaderRef"
                        filterable
                        clearable
                        :placeholder="
                          translateLang('surplusPage.autoCreateColor')
                        "
                      />
                      <transition name="errorInfo">
                        <span
                          v-show="inputAlert.colorInputAlert"
                          class="error-info"
                          >{{ inputAlert.colorInputAlert }}</span
                        >
                      </transition>
                      <div
                        class="newSideAdd"
                        @click="addNewColor"
                        id="supplus_storage_add_color_btn"
                        v-if="!isThinkerxMaterial"
                      >
                        <span>{{ $t('surplusPage.newColor') }}</span>
                      </div>
                    </el-col>
                  </el-row>
                </div>

                <div class="plank-info-item">
                  <el-row>
                    <el-col :span="5">
                      <div style="float: right" class="mr7">
                        <span style="color: red">*</span>
                        <span style="line-height: 30px"
                          >{{ $t('common.count') }}：</span
                        >
                      </div>
                    </el-col>
                    <el-col :span="19">
                      <a-input
                        v-model="newSupplus.amount"
                        :placeholder="translateLang('common.inputPlaceholder')"
                        :class="
                          inputAlert.amountInputAlert ? 'alert-input' : ''
                        "
                        @input="inputCheck('amount')"
                        @blur="inputCheck('amount')"
                        id="supplus_storage_add_amount_input"
                      ></a-input>
                      <transition name="errorInfo">
                        <span
                          v-show="inputAlert.amountInputAlert"
                          class="error-info"
                          >{{ inputAlert.amountInputAlert }}</span
                        >
                      </transition>
                    </el-col>
                  </el-row>
                </div>
                <!-- 高光板 -->
                <div class="plank-info-item">
                  <el-row>
                    <el-col :span="5">
                      <div class="title-box align-right mr7">
                        <span style="color: red">*</span>
                        <span style="line-height: 30px"
                          >{{ $t('common.heighGlossPlank') }}：</span
                        >
                      </div>
                    </el-col>
                    <el-col :span="19">
                      <el-select
                        style="width: 404px"
                        v-model="newSupplus.is_high_gloss_plank"
                        size="small"
                        :placeholder="translateLang('common.isHeightGloss')"
                        id="supplus_storage_add_gloss_select"
                      >
                        <el-option
                          v-for="(item, index) in [
                            { value: 0, label: 'common.notHeighGlossPlank' },
                            { value: 1, label: 'common.heighGlossPlank' },
                          ]"
                          :key="index"
                          :value="item.value"
                          :label="translateLang(item.label)"
                          :id="`supplus_storage_add_gloss_select_${index}`"
                        ></el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </div>
                <div class="plank-info-item">
                  <el-row>
                    <el-col :span="5">
                      <div style="float: right" class="mr7">
                        <span style="line-height: 30px">
                          <!-- 所在余料库 -->
                          {{ t('branchName') }}：
                        </span>
                      </div>
                    </el-col>
                    <el-col :span="19">
                      <el-select
                        style="width: 404px"
                        v-model="newSupplus.branch_no"
                        size="small"
                        placeholder="请选择所在余料库"
                        id="supplus_storage_add_branch_name_select"
                        clearable
                      >
                        <el-option
                          v-for="(item, index) in surplusStoreList"
                          :key="index"
                          :value="item.value"
                          :label="item.label"
                          :id="`supplus_storage_add_branch_name_select_${index}`"
                        ></el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </div>
                <div class="plank-info-item" v-show="isSurplusLock">
                  <el-row>
                    <el-col :span="5">
                      <div style="float: right" class="mr7">
                        <span style="line-height: 30px">
                          <!-- 锁定状态 -->
                          {{ t('lockStatus') }}：
                        </span>
                      </div>
                    </el-col>
                    <el-col :span="19">
                      <el-select
                        style="width: 404px"
                        v-model="newSupplus.lock_status"
                        size="small"
                        placeholder="请选择锁定状态"
                        id="supplus_storage_add_lock_state_select"
                        clearable
                      >
                        <el-option
                          v-for="(item, index) in lockStateList"
                          :key="index"
                          :value="item.value"
                          :label="item.label"
                          :id="`supplus_storage_add_lock_state_select_${index}`"
                        ></el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </div>
                <div class="plank-info-item remark-form">
                  <el-row>
                    <el-col :span="5">
                      <div style="float: right" class="mr7">
                        <span style="line-height: 30px"
                          >{{ $t('materialPage.surplusHead.remark') }}：</span
                        >
                      </div>
                    </el-col>
                    <el-col :span="19" style="margin-top: 10px">
                      <div class="remark-input">
                        <a-textarea
                          style="height: 88px; resize: none"
                          v-model="newSupplus.remark"
                          @input="inputCheck('remark')"
                          @blur="inputCheck('remark')"
                          id="supplus_storage_add_remark_textarea"
                        ></a-textarea>
                        <span class="remark-limit"
                          >{{ newSupplus.remark.length }}/50</span
                        >
                      </div>
                      <transition name="errorInfo">
                        <span
                          v-show="inputAlert.remarkInputAlert"
                          class="error-info remark-info"
                          >{{ inputAlert.remarkInputAlert }}</span
                        >
                      </transition>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="padding-bottom: 30px; text-align: center">
          <div>
            <a-button
              id="supplus_storage_add_cancel_btn"
              @click="addSupplusDrawer = false"
              style="width: 120px"
              >{{ $t('common.cancel') }}</a-button
            >
            <a-button
              @click="saveNewSupplus"
              style="
                width: 120px;
                margin-right: 8px;
                color: #fff;
                background: #18a8c7;
              "
              id="supplus_storage_add_save_btn"
              >{{ $t('common.save') }}</a-button
            >
          </div>
        </div>
      </div>
    </el-drawer>
    <g-base-modal
      :visible="isShowDeleteDialog"
      v-if="isShowDeleteDialog"
      @ok="confirmDeleteSupplus"
      @cancel="isShowDeleteDialog = false"
      :ok-text="translateLang('materialPage.delete.confirm')"
    >
    </g-base-modal>
    <!-- 余料转移弹窗 -->
    <g-base-modal
      :visible="showTransSurplus"
      v-if="showTransSurplus"
      :showIcon="false"
      :title="translateLang('surplusPage.surplusTransfer')"
      :ok-text="translateLang('common.confirm')"
      @cancel="showTransSurplus = false"
      @ok="handleTransSurplus"
    >
      <div class="flex flex-main--center flex-cross--center">
        <!-- 目标余料库 -->
        <g-t-view
          :text="$t('surplusPage.targetSurplusStore')"
          width="130px"
        ></g-t-view>
        <div class="flex-box--3">
          <a-select
            v-model="selectedSurplusStore"
            show-search
            style="width: 313px"
            optionFilterProp="label"
          >
            <a-select-option
              v-for="item in surplusStoreList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </div>
      </div>
    </g-base-modal>
    <!-- 关闭锁定提示弹窗 -->
    <g-base-modal
      :visible="isShowCloseLock"
      v-if="isShowCloseLock"
      @ok="confirmCloseLock"
      @cancel="isShowCloseLock = false"
      :ok-text="translateLang('surplusPage.confirm')"
      :contain="translateLang('surplusPage.isCloseSurplusLock') + '？'"
    >
    </g-base-modal>
  </div>
</template>

<script>
import { buryPoint } from '@/apis/common/buryPoint'
import { surplusLock, surplusTransfer } from '@/apis/surplusManage/index.ts'
import GBaseModal from '@/components/g-base-modal.vue'
import GCopyLine from '@/components/g-copy-line'
import newContent from '@/components/newContent.vue'
import GTView from '@/components/translateComp/g-t-view.vue'
import { updateLocalPreference } from '@/store/modules/preferencesSetting'
import { buryPointFunc } from '@/util/buryPointDeal'
import { EventBusKey } from '@/util/bus'
import EventBus from '@/util/bus'
import {
  checkColorIsExist,
  checkMatCodeIsExist,
  translate,
} from '@/util/commonFun'
import { regReplaceSupplusStorageDealDecimal } from '@/util/regReplace'
import { message } from 'ant-design-vue'
import _ from 'lodash'
import { mapMutations, mapState } from 'vuex'

import drawSupplus from '../../components/draw/drawSupplus.vue'
import { buryPointApi, genFinalRecord, toDecimal } from '../../util/commonFuncs'
import surplusStore from './surplusStore.vue'

const OPTIONS = ['Apples', 'Nails', 'Bananas', 'Helicopters']
export default {
  components: {
    drawSupplus,
    GCopyLine,
    newContent,
    GBaseModal,
    surplusStore,
    GTView,
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      activeKey: '余料清单',
      selectedItems: [],
      addSupplusDrawer: false,
      // 记录绘制点位
      drawObj: {
        width: 172,
        long: 257,
        min_width: 0,
        min_long: 0,
      },
      // 记录绘制的缩放比例
      drawScale: 1,
      // 记录余料库列表
      supplusList: [],
      // 记录当前页码
      page: 1,
      // 记录输入的跳转页码
      jumpPage: '',
      // 记录当前一页显示条数
      pageSize: 10,
      // 记录可切换的pageSize
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      // 记录总条数
      totalSupplus: 1,
      // 记录所有的板材类型的分类
      matcodeList: [],
      // 记录选中的板材类型
      activeMatcode: '全部',
      // 记录是矩形还是L形
      activeShape: '全部',
      // 记录所有板材厚度的分类
      thickList: [],
      // 记录选中的板材厚度
      activeThick: '全部',
      // 判断是否存在筛选
      isFilterStatus: false,
      // 记录所有的板材颜色
      colorList: [],
      // 记录输入的搜索关键词
      keyWord: '',
      // 是否全选板件
      isSelectAll: false,
      // 记录余料表格的列
      supplusColumn: [
        {
          dataIndex: 'select',
          key: 'select',
          width: 60,
          fixed: 'left',
          scopedSlots: { customRender: 'select' },
          slots: { title: 'selectTitle' },
        },
        {
          dataIndex: 'name',
          key: 'name',
          title: '余料名称',
          width: 100,
          fixed: 'left',
          ellipsis: true,
          translateKey: 'surplusPage.surplusName',
        },
        {
          dataIndex: 'surplusType',
          key: 'surplusType',
          title: '板材形状',
          width: 80,
          ellipsis: true,
          scopedSlots: { customRender: 'surplusType' },
          translateKey: 'common.plankShape',
        },
        {
          dataIndex: 'type',
          key: 'type',
          title: '板材材质',
          width: 100,
          ellipsis: true,
          scopedSlots: { customRender: 'type' },
          translateKey: 'common.plankMatCode',
        },
        {
          dataIndex: 'color',
          key: 'color',
          title: '板材颜色',
          width: 80,
          ellipsis: true,
          translateKey: 'common.plankColor',
        },
        {
          dataIndex: 'thick',
          key: 'thick',
          title: '厚度(mm)',
          width: 80,
          ellipsis: true,
          translateKey: 'common.thick',
        },
        {
          dataIndex: 'long',
          key: 'long',
          title: '长度(mm)',
          width: 80,
          ellipsis: true,
          translateKey: 'common.long',
        },
        {
          dataIndex: 'min_long',
          key: 'min_long',
          title: '短边长度(mm)',
          width: 120,
          ellipsis: true,
          translateKey: 'common.shortLong',
        },
        {
          dataIndex: 'width',
          key: 'width',
          title: '宽度(mm)',
          width: 80,
          ellipsis: true,
          translateKey: 'common.width',
        },
        {
          dataIndex: 'min_width',
          key: 'min_width',
          title: '短边宽度(mm)',
          width: 120,
          ellipsis: true,
          translateKey: 'common.shortWidth',
        },
        {
          dataIndex: 'area',
          key: 'area',
          title: '面积(m²)',
          width: 80,
          ellipsis: true,
          translateKey: 'materialPage.surplusHead.area',
        },
        {
          dataIndex: 'amount',
          key: 'amount',
          title: '数量',
          width: 60,
          ellipsis: true,
          translateKey: 'common.count',
        },
        {
          dataIndex: 'is_high_gloss_plank',
          key: 'is_high_gloss_plank',
          title: '高光板',
          width: 110,
          scopedSlots: { customRender: 'is_high_gloss_plank' },
          ellipsis: true,
          translateKey: 'common.heighGlossPlank',
        },
        {
          dataIndex: 'create_time',
          key: 'create_time',
          title: '添加时间',
          width: 100,
          ellipsis: true,
          translateKey: 'surplusPage.createTime',
        },
        {
          dataIndex: 'branch_name',
          key: 'branch_name',
          title: '所在余料库',
          width: 90,
          ellipsis: true,
          translateKey: 'materialPage.branchName',
        },
        {
          dataIndex: 'remark',
          key: 'remark',
          title: '备注',
          width: 100,
          ellipsis: true,
          translateKey: 'materialPage.surplusHead.remark',
        },
        {
          dataIndex: 'operation',
          key: 'operation',
          title: '操作',
          width: 140,
          scopedSlots: { customRender: 'operation' },
          translateKey: 'common.operation',
        },
      ],
      // 记录是否显示添加余料
      showAddNewSupplus: false,
      // 记录添加余料时选择的余料形状, 默认为矩形
      selectedShape: 'rect',
      // 记录新加余料的信息
      newSupplus: {
        name: '',
        long: '',
        min_long: '',
        width: '',
        min_width: '',
        thick: '',
        type: '',
        color: '',
        amount: '1',
        remark: '',
        is_high_gloss_plank: 0,
      },
      // 显示板件颜色下拉框
      showColorMenu: false,
      // 显示板件材质下拉框
      showTypeMenu: false,
      // 显示板件厚度下拉框
      showThickMenu: false,
      // 记录是否需要显示所有子项
      showAllItem: true,
      // 记录选择的子项
      selectedItem: '',
      // 记录各种输入警示
      inputAlert: {
        nameInputAlert: '', // 名称
        longInputAlert: '', // 板材长度
        minLongInputAlert: '', // 板材短边长度
        widthInputAlert: '', // 板材宽度
        minWidthInputAlert: '', // 板材短边宽度
        thickInputAlert: '', // 厚度
        typeInputAlert: '', // 材质
        colorInputAlert: '', // 颜色
        amountInputAlert: '', // 数量
        remarkInputAlert: '', //备注
      },
      // 显示删除确认弹框
      isShowDeleteDialog: false,
      // 记录要删除的余料id
      deleteIds: [],
      // 记录要删除的余料ggid
      deleteGgids: [],
      // 是否显示新建分类的弹窗
      isShowNewClassify: false,
      // 记录当前正在进行哪一个分类的新建
      newClassifyType: 'matcode',
      // 记录输入的新建分类
      newClassify: '',
      // 显示编辑抽屉
      showEditDrawer: false,
      // 记录当前编辑的余料
      currentSurplus: {},
      // 记录选择的搜索类型
      searchType: 'name',
      // 记录是否显示加载
      showLoading: false,
      // 是否为第三方跳转过来
      isThinkerxMaterial: false,
      // 当前点击余料
      currentClickSurplus: {},
      //记录是否少翻版
      isLessReserve: false,
      preferencesSetting: [],
      newPlankInfoTexture: '',
      materialColorOptions: [],
      colorSearchKey: '',
      newSlabColor: '',
      showTransSurplus: false,
      selectedSurplusStore: '',
      // 记录所有的余料库分类
      surplusStoreList: [],
      // 记录选中的余料库
      activeSurplusStore: '全部',
      selectedSurplusList: [],
      activeColor: '全部',
      allColorList: [],
      activeTime: '',
      allMatcodeList: [],
      allThickList: [],
      specialUserIds: [11402, 181028, 483317], // 用户id，第一个是客户正式服的
      // 是否启用余料锁定
      isSurplusLock: false,
      activeLockState: '全部',
      lockStateList: [
        { label: '已锁定', value: 1 },
        { label: '未锁定', value: 0 },
      ],
      // 关闭锁定弹窗显示
      isShowCloseLock: false,
      showBatchLockPop: false,
      // 是否展示余料锁定new标识
      isShowSurLockNew: null,
    }
  },
  computed: {
    ...mapState([
      'isLoadingColors',
      'userInfo',
      'materialColorList',
      'supplusStorageColorList',
      'supplusStorageMatCode',
    ]),
    notAbleDelete() {
      let flag = this.supplusList.some((v) => v.isSelected)
      if (flag) {
        return false
      } else {
        return true
      }
    },
    // 是否是定制用户
    isCustomUser() {
      return this.specialUserIds.includes(this.userInfo?.id)
    },
  },
  methods: {
    ...mapMutations([
      'setSurplusNoRoll',
      'setSupplusStorageColorList',
      'setSupplusStorageMatCode',
    ]),
    handleGoDetail(surplus) {
      this.activeKey = '余料清单'
      this.activeSurplusStore = surplus.branch_no
      this.getSupplusList()
    },
    translateLang(key) {
      return translate(key)
    },
    /** 余料库部分翻译函数 */
    t(k) {
      return translate('surplusPage.' + k)
    },
    /** 公共部分翻译函数 */
    ct(k, val) {
      return translate('common.' + k, val)
    },
    handleChange(e) {
      let timer = null
      if (!e.target.value) {
        if (timer !== null) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          this.page = 1 //time用来控制事件的触发
          this.getSupplusList()
        }, 500)
      }
    },
    // 重置筛选条件
    resetClassify() {
      this.page = 1
      this.activeThick = '全部'
      this.activeShape = '全部'
      this.activeMatcode = '全部'
      this.keyWord = ''
      this.activeSurplusStore = '全部'
      this.activeColor = '全部'
      this.activeTime = ''
      this.activeLockState = '全部'
      this.getSupplusList()
    },
    // 显示余料绘制
    showSupplusShape(visible, supplus) {
      if (visible) {
        if (supplus.long > 257 || supplus.width > 172) {
          let scaleY = supplus.long / 257
          let scaleX = supplus.width / 172
          if (scaleX >= scaleY) {
            this.drawScale = scaleX
          } else {
            this.drawScale = scaleY
          }
        }
        this.drawObj = {
          long: Number(supplus.long),
          width: Number(supplus.width),
          min_long: supplus.min_long == '' ? 0 : Number(supplus.min_long),
          min_width: supplus.min_width == '' ? 0 : Number(supplus.min_width),
        }
      }
    },
    // 全选所有余料
    selectAllSurplus() {
      let status = true
      if (!this.isSelectAll) {
        status = false
      }
      for (let i = 0; i < this.supplusList.length; ++i) {
        this.supplusList[i].isSelected = status
      }
      this.selectedSurplusList = this.supplusList.filter(
        (item) => item.isSelected
      )
      this.$forceUpdate()
    },
    // 选中单个余料
    selectedSurplus(item) {
      let flag = true
      for (let i = 0; i < this.supplusList.length; ++i) {
        if (!this.supplusList[i].isSelected) {
          flag = false
          break
        }
      }
      if (flag) {
        this.isSelectAll = true
      } else {
        this.isSelectAll = false
      }
      this.selectedSurplusList = this.supplusList.filter(
        (item) => item.isSelected
      )
    },
    // 是否显示tooltip
    showTootip(value, surplus, prop) {
      let dom = this.$refs[`surplus_${surplus.id}_${prop}`][0]
      if (dom) {
        if (dom.offsetWidth < dom.scrollWidth) {
          this.$set(surplus, `show${prop}`, true)
        } else {
          this.$set(surplus, `show${prop}`, false)
        }
        if (!value) {
          surplus[`show${prop}`] = false
        }
      }
    },
    // 切换每页显示条数
    onShowSizeChange(val, pageSize) {
      this.pageSize = pageSize
      let currentPage
      if (this.page > Math.ceil(this.totalSupplus / pageSize)) {
        currentPage = Math.ceil(this.totalSupplus / pageSize)
      } else {
        currentPage = this.page
      }
      this.getSupplusList(currentPage)
    },
    // 切换页码
    changeCurrentPage() {
      this.isSelectAll = false
      this.getSupplusList(this.page)
    },
    // 页码输入限制
    pageInputCheck() {
      this.jumpPage = this.jumpPage.replace(/^(0+)|[^\d]+/g, '')
    },
    // 跳转至指定页
    jumpToPage() {
      if (this.jumpPage != '') {
        this.page = Number(this.jumpPage)
        this.getSupplusList(this.page)
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    // 输入框输入事件
    inputCheck(type) {
      switch (type) {
        case 'name':
          if (this.newSupplus.name.length > 10) {
            this.inputAlert.nameInputAlert = translate('surplusPage.nameLimit')
          } else if (this.newSupplus.name == '') {
            this.inputAlert.nameInputAlert = translate(
              'surplusPage.nameLimitNameErr'
            )
          } else {
            this.inputAlert.nameInputAlert = ''
          }
          this.newSupplus.name = this.newSupplus.name.slice(0, 10)
          break
        case 'long':
          if (this.dealDecimal(type)) {
            this.inputAlert.longInputAlert = translate(
              'surplusPage.tempLongTip'
            )
          } else if (
            this.newSupplus.long == '' ||
            Number(this.newSupplus.long) == 0
          ) {
            this.inputAlert.longInputAlert = translate(
              'basePlankManager.heightErr'
            )
          } else {
            this.inputAlert.longInputAlert = ''
          }
          break
        case 'min_long':
          if (this.dealDecimal(type)) {
            this.inputAlert.minLongInputAlert = translate(
              'surplusPage.minLongLimit'
            )
          } else if (this.newSupplus.min_long == '') {
            this.inputAlert.minLongInputAlert = translate(
              'surplusPage.minLongEmptyTip'
            )
          } else if (
            Number(this.newSupplus.min_long) >= Number(this.newSupplus.long)
          ) {
            this.inputAlert.minLongInputAlert = translate(
              'surplusPage.minLongLimitMax'
            )
          } else {
            this.inputAlert.minLongInputAlert = ''
          }
          break
        case 'width':
          if (this.dealDecimal(type)) {
            this.inputAlert.widthInputAlert = translate(
              'surplusPage.widthLimit'
            )
          } else if (
            this.newSupplus.width == '' ||
            Number(this.newSupplus.width) == 0
          ) {
            this.inputAlert.widthInputAlert = translate(
              'basePlankManager.widthErr'
            )
          } else {
            this.inputAlert.widthInputAlert = ''
          }
          break
        case 'min_width':
          if (this.dealDecimal(type)) {
            this.inputAlert.minWidthInputAlert = translate(
              'surplusPage.minWidthLimit'
            )
          } else if (this.newSupplus.min_width == '') {
            this.inputAlert.minWidthInputAlert = translate(
              'surplusPage.minWidthEmptyLimit'
            )
          } else if (
            Number(this.newSupplus.min_width) >= Number(this.newSupplus.width)
          ) {
            this.inputAlert.minWidthInputAlert = translate(
              'surplusPage.minWidthLimitMax'
            )
          } else {
            this.inputAlert.minWidthInputAlert = ''
          }
          break
        case 'thick':
          if (this.dealDecimal(type, 1)) {
            this.inputAlert.thickInputAlert = translate(
              'surplusPage.thickLimit'
            )
          } else if (
            this.newSupplus.thick == '' ||
            Number(this.newSupplus.thick) == 0
          ) {
            this.inputAlert.thickInputAlert = translate(
              'surplusPage.thickEmptyLimit'
            )
          } else {
            this.inputAlert.thickInputAlert = ''
          }
          break
        case 'type':
          if (this.isThinkerxMaterial) {
            this.newSupplus.type = this.newSupplus.type.replace(
              /^\s+|\s+$/g,
              ''
            )
          }
          if (this.newSupplus.type == '') {
            this.inputAlert.typeInputAlert = translate(
              'basePlankManager.matCodeErr'
            )
          } else {
            this.inputAlert.typeInputAlert = ''
          }
          break
        case 'color':
          if (this.isThinkerxMaterial) {
            this.newSupplus.color = this.newSupplus.color.replace(
              /^\s+|\s+$/g,
              ''
            )
          }
          if (this.newPlankInfoTexture == '') {
            this.inputAlert.colorInputAlert = translate(
              'basePlankManager.colorErr'
            )
          } else {
            this.inputAlert.colorInputAlert = ''
          }
          break
        case 'amount':
          let reg = /^(0+)|[^\d]+/
          let flag = false
          if (reg.test(this.newSupplus.amount)) {
            flag = true
          }
          this.newSupplus.amount = (this.newSupplus.amount + '').replace(
            /^(0+)|[^\d]+/g,
            ''
          )
          if (flag) {
            this.inputAlert.amountInputAlert = translate(
              'surplusPage.countInputLimit'
            )
          } else if (this.newSupplus.amount == '') {
            this.inputAlert.amountInputAlert = translate(
              'surplusPage.countEmptyLimit'
            )
          } else {
            this.inputAlert.amountInputAlert = ''
          }
          break
        case 'remark':
          if (this.newSupplus.remark.length > 50) {
            this.inputAlert.remarkInputAlert = translate(
              'surplusPage.remarkMaxlimit'
            )
          } else {
            this.inputAlert.remarkInputAlert = ''
          }
          this.newSupplus.remark = this.newSupplus.remark.slice(0, 50)
          break
      }
    },
    dealDecimal(type, decimal) {
      let reg = /[^\d.]/
      let flag = false
      if (reg.test(this.newSupplus[type])) {
        flag = true
      }
      this.newSupplus[type] = regReplaceSupplusStorageDealDecimal(
        this.newSupplus[type],
        decimal
      )
      return flag
    },
    // 选择子项
    selectMenuItem(item, type) {
      this.selectedItem = item.name
      this.newSupplus[type] = item.name
    },
    // 获取余料仓数据
    getSupplusList(page = 1, isGetSurStore) {
      this.page = page
      if (!isGetSurStore) {
        this.isSelectAll = false
        this.selectedSurplusList = []
      }
      let param = {
        page: page,
        limit: this.pageSize,
      }
      if (this.activeShape != '全部' && this.activeShape != 'all') {
        param.shape = this.activeShape == 'L' ? 1 : 0
        this.isFilterStatus = true
      }
      if (this.activeThick != '全部') {
        param.thick = this.activeThick
        this.isFilterStatus = true
      }
      if (this.activeMatcode != '全部') {
        param.matCode = this.activeMatcode
        this.isFilterStatus = true
      }
      if (this.keyWord != '') {
        param.search_type = this.searchType
        param.search_value = this.keyWord
        this.isFilterStatus = true
        this.page = 1
      }
      if (this.activeSurplusStore != '全部') {
        param.branch_no = this.activeSurplusStore
        this.isFilterStatus = true
      }
      if (this.activeColor != '全部') {
        param.color = this.activeColor
        this.isFilterStatus = true
      }
      if (this.activeTime) {
        param.start_date = this.activeTime[0]
        param.end_date = this.activeTime[1]
        this.isFilterStatus = true
      }
      if (this.activeLockState != '全部') {
        param.lock_status = this.activeLockState
        param.surplus_lock = this.isSurplusLock
        this.isFilterStatus = true
      }
      this.$getByToken('/get_surplus_depot', param, (res) => {
        if (res.status == 1) {
          for (let i = 0; i < res.data.data.length; ++i) {
            const current = res.data.data[i]
            // 翻译
            current.surplusType =
              current.shape == '0' ? 'common.rect' : 'common.Ltype'
            current.isSelected = false
            // 如果是柜柜添加的余料, 则不显示备注信息, 且不可编辑备注信息
            if (current.ggid && current.ggid.length == 4) {
              current.fromGg = true
            } else {
              current.fromGg = false
            }
            if (!current.min_long) {
              current.min_long = 0
            }
            if (!current.min_width) {
              current.min_width = 0
            }
            let area = Number(current.area)
            if (area != 0) {
              current.area = area.toFixed(2)
            }
            // 高光板属性
            current.is_high_gloss_plank = current.type.startsWith('高光_')
              ? 1
              : 0
          }
          if (!isGetSurStore) {
            this.supplusList = res.data.data?.map((item) => ({
              ...item,
              branch_name: item.branch_name || '-',
              branch_no:
                item.branch_no == '000' || item.branch_no == '0'
                  ? ''
                  : item.branch_no,
              create_time: item.create_time.split(' ')[0],
            }))
          } else {
            res.data.data?.forEach((item) => {
              this.supplusList.forEach((supplus) => {
                if (item.id == supplus.id) {
                  supplus.branch_name = item.branch_name || '-'
                  supplus.branch_no = item.branch_no
                }
              })
            })
          }
          this.totalSupplus = res.data.total
          this.showLoading = false
          this.allMatcodeList = res.data.mat_code_list?.filter((item) => item)
          this.allThickList = res.data.thick_list?.filter((item) => item)
          this.allColorList = res.data.color_list?.map((item) => ({
            label: item,
            value: item,
          }))
          this.surplusStoreList = res.data.surplus_warehouse_list?.map(
            (item) => ({
              ...item,
              value: item.branch_no,
              label: item.branch_name,
            })
          )
          if (res.data.surplus_warehouse_list.length) {
            this.selectedSurplusStore =
              res.data.surplus_warehouse_list[0].branch_no
          } else {
            this.selectedSurplusStore = ''
          }
        }
      })
    },
    // 获取余料筛选项
    async getSurplusClassify() {
      await this.$getByToken('/get_user_plank_setting', {}, (res) => {
        if (res.status == 1) {
          const otherMatCodes = localStorage.getItem('matcodeList')
            ? JSON.parse(localStorage.getItem('matcodeList'))
            : []
          this.matcodeList = res.data.matCodes.concat(otherMatCodes)
          this.colorList = res.data.colors
          this.thickList = res.data.thicks
          this.isShowNewClassify = false
          this.newSupplus.name = res.data.next_surplus_name
        }
      })
    },
    // 显示删除余料弹窗
    showDeleteDialog(val) {
      this.deleteIds = []
      this.deleteGgids = []
      if (val) {
        this.deleteIds = [val.id]
        this.deleteGgids = [val.ggid]
      } else {
        for (let i = 0; i < this.supplusList.length; ++i) {
          if (this.supplusList[i].isSelected) {
            this.deleteIds.push(this.supplusList[i].id)
            this.deleteGgids.push(this.supplusList[i].ggid)
          }
        }
      }
      this.isShowDeleteDialog = true
    },
    // 显示转移弹窗
    handleShowTransSurplus(val) {
      this.deleteIds = []
      this.deleteGgids = []
      if (val) {
        this.deleteIds = [val.id]
        this.deleteGgids = [val.ggid]
      } else {
        for (let i = 0; i < this.supplusList.length; ++i) {
          if (this.supplusList[i].isSelected) {
            this.deleteIds.push(this.supplusList[i].id)
            this.deleteGgids.push(this.supplusList[i].ggid)
          }
        }
      }
      this.showTransSurplus = true
    },
    // 确认转移
    handleTransSurplus() {
      if (!this.selectedSurplusStore) {
        this.$message.error('暂无余料库请创建后转移！')
        this.showTransSurplus = false
        return
      }
      const params = {
        roll_out_surplus_ids: this.deleteIds,
        roll_out_ggids: this.deleteGgids,
        roll_in_branch_no: this.selectedSurplusStore,
      }
      surplusTransfer(params).then((res) => {
        if (res.status == 1) {
          this.$message.success('转移成功')
          this.getSupplusList(this.page)
          this.selectedSurplusList = []
          buryPoint({
            function_module: 'supplus_storage',
            function_point: 'transfer_surplus',
          })
        } else {
          this.$message.error(res.msg)
        }
      })
      this.showTransSurplus = false
    },
    // 确认删除余料
    confirmDeleteSupplus() {
      this.$token(
        '/delete_surplus_depot',
        {
          ggids: this.deleteGgids,
          ids: this.deleteIds,
        },
        (res) => {
          if (res.status == 1) {
            if (this.totalSupplus % this.pageSize == 1 && this.page > 1) {
              this.page = this.page - 1
              this.getSupplusList(this.page)
            }
            this.getSupplusList(this.page)
            this.selectedSurplusList = []
            this.$message({
              message: '删除成功',
              type: 'success',
              duration: 2000,
            })
          } else {
            this.$message({
              message: '删除失败',
              type: 'error',
              duration: 2000,
            })
          }
        }
      )
      this.isShowDeleteDialog = false
    },
    // 切换新增余料时选择的余料
    changeSelectedShape(type) {
      this.selectedShape = type
      // 切换时, 去除报警
      this.inputAlert = {
        nameInputAlert: '',
        longInputAlert: '',
        minLongInputAlert: '',
        widthInputAlert: '',
        minWidthInputAlert: '',
        thickInputAlert: '',
        typeInputAlert: '',
        colorInputAlert: '',
        amountInputAlert: '',
        remarkInputAlert: '',
      }
      let name = this.newSupplus.name
      this.newSupplus = {
        name: name,
        long: '',
        min_long: '',
        width: '',
        min_width: '',
        thick: '',
        type: this.supplusStorageMatCode,
        color: this.supplusStorageColorList[this.supplusStorageColorList - 1],
        amount: '1',
        remark: '',
      }
      this.newSupplus
      if (type == 'rect') {
        this.drawObj = {
          width: 172,
          long: 257,
          min_width: 0,
          min_long: 0,
        }
      } else {
        this.drawObj = {
          width: 172,
          long: 257,
          min_width: 81,
          min_long: 111,
        }
      }
      this.$forceUpdate()
    },
    // 新增余料
    async addNewSupplus() {
      this.newSupplus = {
        name: '',
        long: '',
        min_long: '',
        width: '',
        min_width: '',
        thick: '',
        type: '',
        color: '',
        amount: '1',
        remark: '',
      }
      await this.getSurplusClassify()
      this.drawObj = {
        width: 172,
        long: 257,
        min_width: 0,
        min_long: 0,
      }
      this.selectedShape = 'rect'
      this.showAddNewSupplus = true
    },
    // 取消新增余料
    cancelAddNewSupplus() {
      this.showAddNewSupplus = false
      // 关闭时, 去除报警
      this.inputAlert = {
        nameInputAlert: '',
        longInputAlert: '',
        minLongInputAlert: '',
        widthInputAlert: '',
        minWidthInputAlert: '',
        thickInputAlert: '',
        typeInputAlert: '',
        colorInputAlert: '',
        amountInputAlert: '',
        remarkInputAlert: '',
      }
    },
    // 保存新增余料
    saveNewSupplus(cb, isCopy = false) {
      // 判断是否可以保存
      if (!isCopy) {
        this.newSupplus.color =
          this.newPlankInfoTexture[this.newPlankInfoTexture.length - 1]
      }
      let ableSave = this.checkInputIsBlank()
      if (!ableSave) return
      this.showLoading = true
      let newSurplus = JSON.parse(JSON.stringify(this.newSupplus))
      let area = 0
      newSurplus.long = Number(newSurplus.long)
      newSurplus.width = Number(newSurplus.width)
      newSurplus.amount = Number(newSurplus.amount)
      newSurplus.thick = Number(newSurplus.thick)
      if (this.selectedShape == 'L') {
        area =
          newSurplus.long * newSurplus.width -
          (newSurplus.long - newSurplus.min_long) *
            (newSurplus.width - newSurplus.min_width)
        newSurplus.min_long = Number(newSurplus.min_long)
        newSurplus.min_width = Number(newSurplus.min_width)
      } else {
        area = newSurplus.long * newSurplus.width
      }
      area = toDecimal(area / 1000000, 2)
      newSurplus.color = isCopy
        ? newSurplus.color.split('@_@')[0].trim()
        : this.newPlankInfoTexture[this.newPlankInfoTexture.length - 1].trim()
      newSurplus.type = newSurplus.type.split('@_@')[0].trim()
      newSurplus.shape = this.selectedShape == 'L' ? 1 : 0
      newSurplus.area = area
      newSurplus.type = this.handlePlankMatCode(
        newSurplus,
        newSurplus.is_high_gloss_plank
      )
      newSurplus.branch_no = newSurplus.branch_no
      newSurplus.name = newSurplus.name.trim()
      newSurplus.surplus_lock = this.isSurplusLock
      newSurplus.lock_status = newSurplus.lock_status
      newSurplus.lock_num = newSurplus.amount
      // 新接口为满足批量添加
      // const data = {
      //   surplus_data: [],
      // }
      // data.surplus_data.push(newSurplus)
      this.$token(
        '/create_surplus_depot',
        { surplus_data: [newSurplus], designated: true },
        (res) => {
          if (res.status == 1) {
            this.showAddNewSupplus = false
            this.addSupplusDrawer = false
            this.getSupplusList(1)
            cb && typeof cb == 'function'
            if (cb && typeof cb == 'function') {
              cb()
            } else {
              this.$message.success(`添加余料成功`)
            }
          }
          if (res.status == 0) {
            this.$message({
              type: 'error',
              message: res.msg,
            })
            this.showLoading = false
            // this.addSupplusDrawer = false
          }
        }
      )
      // this.$token('/create_surplus_depot', newSurplus, (res) => {
      //   if (res.status == 1) {
      //     this.showAddNewSupplus = false
      //     this.addSupplusDrawer = false
      //     this.getSupplusList(1)
      //   }
      //   if (res.status == 0) {
      //     this.$message({
      //       type: 'error',
      //       message: res.msg,
      //     })
      //     this.showLoading = false
      //     this.addSupplusDrawer = false
      //   }
      // })
    },
    // 判断输入是否为空
    checkInputIsBlank() {
      let ableSave = true
      if (this.newSupplus.name == '') {
        this.inputAlert.nameInputAlert = '余料名称不能为空!'
        ableSave = false
      }
      if (this.newSupplus.long == '') {
        this.inputAlert.longInputAlert = '板材长度不能为空!'
        ableSave = false
      }
      if (Number(this.newSupplus.long) == 0) {
        this.inputAlert.longInputAlert = '板材长度不能为0!'
        ableSave = false
      }
      if (this.newSupplus.min_long == '' && this.selectedShape == 'L') {
        this.inputAlert.minLongInputAlert = '短边长度不能为空!'
        ableSave = false
      }
      if (this.newSupplus.min_long != '' && this.selectedShape == 'L') {
        if (Number(this.newSupplus.min_long) == 0) {
          this.inputAlert.minLongInputAlert = '短边长度不能为0!'
          ableSave = false
        }
        if (Number(this.newSupplus.min_long) >= Number(this.newSupplus.long)) {
          this.inputAlert.minLongInputAlert = '短边长度不能超过板材长度!'
          ableSave = false
        }
      }
      if (this.newSupplus.width == '') {
        this.inputAlert.widthInputAlert = '板材宽度不能为空!'
        ableSave = false
      }
      if (Number(this.newSupplus.width) == 0) {
        this.inputAlert.widthInputAlert = '板材宽度不能为0!'
        ableSave = false
      }
      if (this.newSupplus.min_width == '' && this.selectedShape == 'L') {
        this.inputAlert.minWidthInputAlert = '短边宽度不能为空!'
        ableSave = false
      }
      if (this.newSupplus.min_width != '' && this.selectedShape == 'L') {
        if (Number(this.newSupplus.min_width) == 0) {
          this.inputAlert.minWidthInputAlert = '短边宽度不能为0!'
          ableSave = false
        }
        if (
          Number(this.newSupplus.min_width) >= Number(this.newSupplus.width)
        ) {
          this.inputAlert.minWidthInputAlert = '短边宽度不能超过板材宽度!'
          ableSave = false
        }
      }
      if (Number(this.newSupplus.thick) == 0) {
        this.inputAlert.thickInputAlert = '板材厚度不能为空!'
        ableSave = false
      }
      if (this.newSupplus.type == '') {
        this.inputAlert.typeInputAlert = '板材材质不能为空!'
        ableSave = false
      }
      if (
        !this.newSupplus.color ||
        this.newSupplus.color.includes('_catalog')
      ) {
        this.inputAlert.colorInputAlert = '板材颜色不能为空!'
        ableSave = false
      }
      if (Number(this.newSupplus.amount) == 0) {
        this.inputAlert.amountInputAlert = '数量不能为空!'
        ableSave = false
      }
      return ableSave
    },
    // 新建材质
    addNewMatcode() {
      this.newClassifyType = 'matcode'
      this.isShowNewClassify = true
    },
    // 新建颜色
    addNewColor() {
      this.newClassifyType = 'color'
      this.isShowNewClassify = true
    },
    // 关闭新建分类弹窗
    closeNewClassify() {
      this.isShowNewClassify = false
      this.newClassify = ''
    },
    // 保存新建的分类
    saveNewClassify() {
      if (this.newClassify == '') {
        this.$message({
          message:
            this.newClassifyType == 'matcode'
              ? '材质不能为空!'
              : '颜色不能为空!',
          type: 'error',
        })
        return
      }
      if (this.newClassifyType == 'matcode') {
        // this.$token(
        //   '/create_matcode',
        //   {
        //     name: this.newClassify,
        //   },
        //   (res) => {
        //     if (res.status == 1) {
        //       this.matcodeList.push(this.newClassify)
        //       this.newClassify = ''
        //       this.isShowNewClassify = false
        //     }
        //   }
        // )
        const matCodeStr = this.newClassify
        const checkMatCodeIsExistList = this.matcodeList.map((item) => {
          return {
            label: item,
            value: item,
          }
        })
        const isExist = checkMatCodeIsExist(matCodeStr, checkMatCodeIsExistList)
        if (isExist) {
          this.$message.error(`材质:${matCodeStr}已存在`)
        } else {
          let otherMatCodes = localStorage.getItem('matcodeList')
            ? JSON.parse(localStorage.getItem('matcodeList'))
            : []
          otherMatCodes.push(matCodeStr)
          localStorage.setItem('matcodeList', JSON.stringify(otherMatCodes))
          this.matcodeList.push(matCodeStr)
          this.newSupplus.type = matCodeStr
          this.newClassify = ''
          this.isShowNewClassify = false
        }
      }
      if (this.newClassifyType == 'color') {
        const colorStr = this.newClassify
        const isExist = checkColorIsExist(colorStr, this.materialColorOptions)
        if (isExist) {
          this.$message.error(`颜色:${colorStr}已存在`)
        } else {
          let otherColors = localStorage.getItem('colorList')
            ? JSON.parse(localStorage.getItem('colorList'))
            : []
          otherColors.push(colorStr)
          this.newPlankInfoTexture = ['other', colorStr]
          localStorage.setItem('colorList', JSON.stringify(otherColors))
          const lastIndex = this.materialColorOptions.length - 1
          this.materialColorOptions[lastIndex].children.push({
            label: colorStr,
            value: colorStr,
          })
          this.newClassify = ''
          this.isShowNewClassify = false
        }
      }
    },
    // 显示编辑
    showEditSurplus(item) {
      this.inputAlert = {
        nameInputAlert: '',
        longInputAlert: '',
        minLongInputAlert: '',
        widthInputAlert: '',
        minWidthInputAlert: '',
        thickInputAlert: '',
        typeInputAlert: '',
        colorInputAlert: '',
        amountInputAlert: '',
        remarkInputAlert: '',
      }
      this.newSupplus = {
        name: item.name ? item.name : '',
        long: item.long,
        min_long: item.min_long,
        width: item.width,
        min_width: item.min_width,
        thick: item.thick,
        type: item.type.replaceAll('高光_', ''),
        color: item.color,
        amount: item.amount,
        remark: item.remark ? item.remark : '',
        fromGg: item.fromGg,
        is_high_gloss_plank: item.is_high_gloss_plank,
        branch_no:
          item.branch_no == '000' || item.branch_no == '0'
            ? ''
            : item.branch_no,
        lock_status: item.lock_status,
        lock_order: item.lock_order,
      }
      this.selectedShape = 'rect'
      if (item.min_long != 0 && item.min_long != '') {
        this.selectedShape = 'L'
      }
      this.showEditDrawer = true
      this.currentSurplus = item
      this.colorSearchKey = item.color
      this.handleEnterColor()
    },
    // 确认修改编辑
    confirmEditSurplus() {
      this.newSupplus.color = this.newPlankInfoTexture
        ? this.newPlankInfoTexture[this.newPlankInfoTexture.length - 1]
        : this.newSupplus.color.split('@_@')[0]
      if (!this.newSupplus.color || this.newSupplus.color.includes('_catalog'))
        return
      // 判断是否可以保存
      let ableSave = this.checkInputIsBlank()
      if (!ableSave) {
        this.$message.error('存在未填写的信息, 请核对!')
        return
      }
      let area = 0
      if (this.selectedShape == 'L') {
        area =
          this.newSupplus.long * this.newSupplus.width -
          (this.newSupplus.long - this.newSupplus.min_long) *
            (this.newSupplus.width - this.newSupplus.min_width)
      } else {
        area = this.newSupplus.long * this.newSupplus.width
      }
      area = toDecimal(area / 1000000, 2)
      let splitValue = '@@'

      const data = {
        id: this.currentSurplus.id,
        // 根据高光板属性重置板件材质
        type: this.newSupplus.type.split(splitValue)[0],
        name: this.newSupplus.name,
        thick: this.newSupplus.thick,
        area: area,
        width: this.newSupplus.width,
        long: this.newSupplus.long,
        color: this.newSupplus.color,
        ggid: this.currentSurplus.ggid,
        amount: this.newSupplus.amount,
        shape: this.currentSurplus.shape,
        remark: this.newSupplus.remark,
        min_long: this.newSupplus.min_long,
        min_width: this.newSupplus.min_width,
        branch_no: this.newSupplus.branch_no,
        surplus_lock: this.surplusLock,
        lock_status: this.newSupplus.lock_status,
        lock_order: this.newSupplus.lock_order,
        lock_num: this.newSupplus.amount,
      }
      data.type = this.handlePlankMatCode(
        this.newSupplus,
        this.newSupplus.is_high_gloss_plank
      )
      data.type = data.type.split(splitValue)[0]
      // currentSurplus
      this.$token('/update_surplus_depot', data, (res) => {
        if (res.status == 1) {
          this.$message({
            message: '修改成功!',
            type: 'success',
          })
          // 调用重新请求当前页面
          this.getSupplusList(this.page)
          this.showEditDrawer = false
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
          })
        }
      })
    },
    // 关闭编辑
    closeEditDrawer() {
      this.showEditDrawer = false
      this.colorSearchKey = ''
      this.newPlankInfoTexture = ''
      // 关闭时, 去除报警
      this.inputAlert = {
        nameInputAlert: '',
        longInputAlert: '',
        minLongInputAlert: '',
        widthInputAlert: '',
        minWidthInputAlert: '',
        thickInputAlert: '',
        typeInputAlert: '',
        colorInputAlert: '',
        amountInputAlert: '',
        remarkInputAlert: '',
      }
    },
    /**
     * @author lkk
     * @description 添加余料改为侧边栏显示
     */
    addNewSupplusSide() {
      buryPointApi('supplus_storage', 'add')
      this.addSupplusDrawer = true
      Object.keys(this.newSupplus).forEach((key) => {
        if (key != 'amount') {
          this.newSupplus[key] = ''
        } else {
          this.newSupplus[key] = 1
        }
        if (key === 'is_high_gloss_plank') {
          this.newSupplus[key] = 0
        }
      })
      this.newPlankInfoTexture = this.supplusStorageColorList
      this.newSupplus.color =
        this.supplusStorageColorList[this.supplusStorageColorList.length - 1] ??
        ''
      this.newSupplus.type = this.supplusStorageMatCode
      this.getSurplusClassify()
    },
    // 余料板材颜色 || 余料板材材质->回车操作
    colorOrTypeSelectEnter(callback, type) {
      const selectRef = this.$refs[type]
      const inputEl = selectRef.$el.querySelector('input')
      if (callback) {
        inputEl.addEventListener('keyup', (e) => {
          if (e.code === 'Enter') {
            this.newSupplus[type] = inputEl.value
            selectRef.blur()
          }
        })
      }
    },
    handleClickSupplus(supplus) {
      this.currentClickSurplus = supplus
    },
    // 复制行
    async copyLine(supplus) {
      this.newSupplus = {
        name: '',
        long: supplus.long,
        min_long: supplus.min_long,
        width: supplus.width,
        min_width: supplus.min_width,
        thick: supplus.thick,
        type: supplus.type,
        color: supplus.color,
        amount: '1',
        remark: supplus.remark,
        is_high_gloss_plank: supplus.is_high_gloss_plank,
      }
      await this.getSurplusClassify()
      this.saveNewSupplus(() => {
        this.$message.success(`复制[${this.currentClickSurplus.name}]数据成功`)
        this.selectedSurplusList = []
      }, true)
    },
    handlePlankMatCode(part, type) {
      let { type: matCode } = part
      // 确定新的材质
      if (matCode.startsWith('高光_') && !type) {
        matCode = matCode.replace('高光_', '')
      } else if (!matCode.startsWith('高光_') && type) {
        matCode = '高光_' + matCode
      }
      return matCode
    },
    onHighPlankChange(item) {
      this.newSupplus = {
        name: item.name ? item.name : '',
        long: item.long,
        min_long: item.min_long,
        width: item.width,
        min_width: item.min_width,
        thick: item.thick,
        type: item.type,
        color: item.color,
        amount: item.amount,
        remark: item.remark ? item.remark : '',
        fromGg: item.fromGg,
        is_high_gloss_plank: item.is_high_gloss_plank,
        branch_no: item.branch_no,
        lock_status: item.lock_status,
        lock_order: item.lock_order,
      }
      this.selectedShape = 'rect'
      if (item.min_long != 0 && item.min_long != '') {
        this.selectedShape = 'L'
      }
      this.currentSurplus = item
      this.confirmEditSurplus()
    },
    //切换少翻版
    handleLessReserve({ target }) {
      updateLocalPreference({ surplus_no_roll: target.checked })
      if (this.isLessReserve) {
        buryPointFunc('余料管理-不翻版加工')
      }
      this.setSurplusNoRoll(target.checked)
    },
    handleSelectTexture(val) {
      this.newSlabColor = val[val.length - 1]
      this.colorSearchKey = ''
      this.inputCheck('color')
      this.setSupplusStorageColorList(val)
    },
    handleSelectMatCode(val) {
      this.inputCheck('type')
      if (val?.includes('@_@')) val = val.split('@_@')[0]
      this.newSupplus.type = val
      this.setSupplusStorageMatCode(val)
    },
    onBeforeFilter(val) {
      this.colorSearchKey = val
    },
    // 回车时对材质进行的操作
    handleEnterMatCode(e) {
      // 当为空或者全是空格时不进行操作
      const inputMatCode = e?.target?.value ?? this.newSupplus.type
      if (!inputMatCode) return
      // 将matcodeList数组变成label value的形式
      const checkMatCodeIsExistList = this.matcodeList.map((item) => {
        return {
          label: item,
          value: item,
        }
      })
      // 判断数组是否已经存在当前添加的数据
      const isExist = checkMatCodeIsExist(inputMatCode, checkMatCodeIsExistList)
      // 获取本地的材质数据
      let otherMatCodes = localStorage.getItem('matcodeList')
        ? JSON.parse(localStorage.getItem('matcodeList'))
        : []
      if (!isExist) {
        // 如果不存在
        otherMatCodes.push(inputMatCode)
        localStorage.setItem('matcodeList', JSON.stringify(otherMatCodes))
        this.matcodeList.push(inputMatCode)
      }
      this.newSupplus.type = inputMatCode
      this.setSupplusStorageMatCode(inputMatCode)
    },
    handleEnterColor(e) {
      if (!this.colorSearchKey) return
      const isExist = checkColorIsExist(
        this.colorSearchKey,
        this.materialColorOptions
      )
      let otherColors = localStorage.getItem('colorList')
        ? JSON.parse(localStorage.getItem('colorList'))
        : []
      if (isExist) {
        this.newPlankInfoTexture = isExist
      } else {
        otherColors.push(this.colorSearchKey)
        this.newPlankInfoTexture = ['other', this.colorSearchKey]
        localStorage.setItem('colorList', JSON.stringify(otherColors))
        const lastIndex = this.materialColorOptions.length - 1
        this.materialColorOptions[lastIndex].children.push({
          label: this.colorSearchKey,
          value: this.colorSearchKey,
        })
      }
      if (e?.target?.value) {
        this.setSupplusStorageColorList(this.newPlankInfoTexture)
      }
    },
    cacleColorItem(list) {
      let newList = []
      list.forEach((item) => {
        let currentItem = {
          children: [],
          value: item.name,
          label: item.name,
        }
        if (item.children.length) {
          currentItem.children = this.cacleColorItem(item.children)
        }
        if (item.color) {
          let itemColors = item.color.map((e) => {
            return {
              label: e.color_name,
              value: e.color_name,
            }
          })
          currentItem.children = currentItem.children.concat(itemColors)
        }
        newList.push(currentItem)
      })
      return newList
    },
    handleCloseDrawer() {
      this.newPlankInfoTexture = ''
      this.colorSearchKey = ''
    },

    handleDeleteColorSearch(type) {
      const ref =
        type == 'edit'
          ? this.$refs['editColorCascaderRef']
          : this.$refs['addColorCascaderRef']
      this.colorSearchKey = ref.$children[0].value
    },
    handleChangeTab(val) {
      if (val == '余料清单') {
        this.getSupplusList(this.page, true)
        this.updateTransEmpty()
      }
    },
    // 余料锁定开启/关闭
    handleChangeSurplusLock(e) {
      if (this.isSurplusLock) {
        this.supplusColumn.splice(
          15,
          0,
          {
            dataIndex: 'lock_status',
            key: 'lock_status',
            title: '锁定状态',
            width: 90,
            ellipsis: true,
            scopedSlots: { customRender: 'lock_status' },
            translateKey: 'surplusPage.lockStatus',
          },
          {
            dataIndex: 'lock_order',
            key: 'lock_order',
            title: '锁定订单',
            width: 170,
            ellipsis: true,
            scopedSlots: { customRender: 'lock_order' },
            translateKey: 'surplusPage.lockOrder',
          }
        )

        this.isShowSurLockNew = true
        updateLocalPreference({
          surplus_lock: this.isSurplusLock,
          isShowSurLockNew: true,
        })
        this.updateTransSurplusTable()
        buryPoint({
          function_module: 'supplus_storage',
          function_point: 'surplusLock',
        })
        genFinalRecord(
          {
            key: '',
            operateArr: [
              {
                key: '',
                dataArr: [
                  {
                    type: 'form',
                    current: {
                      surplusLock: this.isSurplusLock ? '开启' : '关闭',
                    },
                    ori: { surplusLock: '关闭' },
                    compareMsg: [
                      {
                        title: '',
                        keys: ['surplusLock'],
                      },
                    ], // 要比较的内容
                    formTextObj: {
                      surplusLock: '余料锁定功能',
                    }, // key对应的意思
                    formValueObj: {}, // value对应的意思
                  },
                ],
              },
            ],
          },
          '余料清单'
        )
      } else {
        this.isShowCloseLock = true
        this.isSurplusLock = true
      }
    },
    // 确认关闭余料锁定
    async confirmCloseLock() {
      this.supplusColumn.splice(15, 2)
      this.isShowCloseLock = false
      this.isSurplusLock = false
      this.isShowSurLockNew = true
      updateLocalPreference({
        surplus_lock: this.isSurplusLock,
        isShowSurLockNew: true,
      })
      await surplusLock({ surplus: [], release_all: true })
      this.activeLockState = '全部'
      this.getSupplusList(this.page)
      genFinalRecord(
        {
          key: '',
          operateArr: [
            {
              key: '',
              dataArr: [
                {
                  type: 'form',
                  current: {
                    surplusLock: this.isSurplusLock ? '开启' : '关闭',
                  },
                  ori: { surplusLock: '开启' },
                  compareMsg: [
                    {
                      title: '',
                      keys: ['surplusLock'],
                    },
                  ], // 要比较的内容
                  formTextObj: {
                    surplusLock: '余料锁定功能',
                  }, // key对应的意思
                  formValueObj: {}, // value对应的意思
                },
              ],
            },
          ],
        },
        '余料清单'
      )
    },
    // 单个锁定
    handleLock(row) {
      const params = {
        surplus: [
          {
            ...row,
            lock_num: row.amount,
            lock_status: row.lock_status ? 0 : 1,
          },
        ],
        release_all: false,
      }
      surplusLock(params).then((res) => {
        if (res.status == 1) {
          this.$message.success(row.lock_status ? '解锁成功' : '锁定成功')
          this.getSupplusList(this.page)
        } else {
          this.$message.error(row.lock_status ? '解锁失败' : '锁定失败')
        }
      })
    },
    // 批量解锁/锁定
    handleBatchLock(type) {
      this.showBatchLockPop = false
      const params = {
        surplus: this.selectedSurplusList.map((item) => ({
          ...item,
          lock_num: item.amount,
          lock_status: type,
        })),
        release_all: false,
      }
      surplusLock(params).then((res) => {
        if (res.status == 1) {
          this.$message.success(type ? '批量锁定成功' : '批量解锁成功')
          this.getSupplusList(this.page)
        } else {
          this.$message.error(type ? '批量锁定失败' : '批量解锁失败')
        }
      })
    },
    // 表格行点击事件
    handleClickTableRow(row, index) {
      return {
        on: {
          click: () => {
            this.currentClickSurplus = row
          },
        },
      }
    },
    // 翻译余料表格表头列
    updateTransSurplusTable() {
      this.supplusColumn = this.supplusColumn.map((item) => {
        if (!['select', 'branch_name'].includes(item.key)) {
          return {
            ...item,
            title: translate(item.translateKey),
            // 自定义头部单元格
            customHeaderCell: () => ({
              attrs: {
                // 可以在这里添加额外的属性，比如 title
                title: translate(item.translateKey),
              },
            }),
          }
        } else {
          return item
        }
      })
    },
    updateTransEmpty() {
      if (this.totalSupplus == 0) {
        this.$nextTick(() => {
          const emptyDom = document.querySelector(
            '.surplus-table .ant-empty-description'
          )
          if (emptyDom) {
            if (!this.isFilterStatus) {
              emptyDom.innerHTML = `${this.$t('common.notDataTipByType', {})}
            <span
              id="supplus_store_table_add_btn"
              style="color: #18a8c7; cursor: pointer"
            >${this.$t('surplusPage.addSurplus')}</span>
          `
            } else {
              // 未查询到相关数据
              emptyDom.innerHTML = this.$t('surplusPage.noMatchSup')
            }
          }
          const addBtn = document.querySelector('#supplus_store_table_add_btn')
          if (addBtn) {
            addBtn.addEventListener('click', this.addNewSupplusSide)
          }
        })
      }
    },
    // 刷新
    handleRefresh() {
      this.showLoading = true
      this.getSupplusList()
    },
    // 余料详情popover方法-浮层渲染父节点，默认渲染到 body 上
    getPopupContainer() {
      return document.body
    },
    updatePreference({ data, isErr }) {
      if (isErr) return
      data = data || this.$store.state.preferencesSetting.setting
      this.isLessReserve = data.surplus_no_roll
      this.isLessReserve = data.surplus_no_roll
      this.isSurplusLock = data.surplus_lock
      this.isShowSurLockNew = data.isShowSurLockNew
      this.setSurplusNoRoll(data.surplus_no_roll)
      if (this.isSurplusLock) {
        this.supplusColumn.splice(
          15,
          0,
          {
            dataIndex: 'lock_status',
            key: 'lock_status',
            title: '锁定状态',
            width: 90,
            ellipsis: true,
            scopedSlots: { customRender: 'lock_status' },
            translateKey: 'surplusPage.lockStatus',
          },
          {
            dataIndex: 'lock_order',
            key: 'lock_order',
            title: '锁定订单',
            width: 170,
            ellipsis: true,
            scopedSlots: { customRender: 'lock_order' },
            translateKey: 'surplusPage.lockOrder',
          }
        )
      }
    },
    /**
     * @description 添加事件监听
     */
    addBusEvent() {
      EventBus.$on(EventBusKey.TRANSLATE, this.updateTransSurplusTable)
      EventBus.$on(EventBusKey.TRANSLATE, this.updateTransEmpty)
    },
    /**
     * @description 取消事件监听
     */
    removeBusEvent() {
      EventBus.$off(EventBusKey.TRANSLATE, this.updateTransSurplusTable)
      EventBus.$off(EventBusKey.TRANSLATE, this.updateTransEmpty)
    },
  },

  mounted() {
    // 挂载偏好设置的更新
    this.$preference.init(this.updatePreference, this.$route.path)
    this.addBusEvent()
    this.showLoading = true
    this.getSurplusClassify()
    this.getSupplusList()
    if (!this.materialColorOptions.length) {
      this.materialColorOptions = this.materialColorList
    }
    this.updateTransSurplusTable()
  },
  unmounted() {
    this.removeBusEvent()
  },
  created() {
    if (sessionStorage.getItem('thinkerx_material')) {
      this.isThinkerxMaterial = true
    }
  },
  watch: {
    materialColorList: {
      handler(val) {
        this.materialColorOptions = val
      },
    },
    totalSupplus: {
      handler() {
        this.updateTransEmpty()
      },
    },
  },
}
</script>

<style scoped lang="less">
/deep/.el-range-separator {
  padding: 0px;
}
/deep/ .el-range__icon,
.el-range-separator {
  display: flex;
  align-items: center;
}
/deep/.el-range-separator {
  display: flex;
  align-items: center;
}
.supplus-storage {
  min-width: 1440px;
  margin: 16px;
  margin-bottom: 0px;
  background: #eeeeee;

  .flag {
    line-height: normal;
    display: block;
    width: fit-content;
    color: #000;
    background-color: rgb(249, 224, 199);
    position: absolute;
    left: 185px;
    top: 12px;
    z-index: 1000;
    white-space: nowrap;
    padding: 2px 5px;
    font-size: 12px;
    border-radius: 3px;
  }
  .special-flag {
    color: #fff;
    background-color: #fcdb3f;
  }

  span {
    font-family: PingFangSC-Medium, PingFang SC;
  }

  .supplus-content {
    .top-operation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fff;
    }

    .filter {
      min-height: 160px;
      background: #f5f5f5;
      padding-left: 34px;
      .label {
        min-width: 70px;
        display: inline-block;
        font-size: 14px;
        text-align: right;
        color: rgba(0, 0, 0, 0.9);
      }
      &-item {
        margin-right: 70px;
        margin-bottom: 16px;
        /deep/.el-input__inner {
          height: 32px;
          padding-left: 10px;
        }
        /deep/.el-input__prefix {
          display: none;
        }
        /deep/.el-input__suffix {
          height: 32px;
          display: flex;
          align-items: center;
        }
      }
      &-btn {
        margin-left: 70px;
      }
    }

    .left-operation {
      display: flex;
      align-items: center;
    }

    .supplus-btn {
      display: flex;
      align-items: center;
      width: 110px;
      height: 32px;

      .iconfont {
        margin-right: 3px;
      }
    }

    .new-supplus {
      margin-right: 8px;
      color: #fff;
      background: #18a8c7;
      border: none;
    }

    .delete-btn-hover {
      color: #333;

      &:hover {
        color: #18a8c7;
      }
    }

    .right-filter {
      display: flex;
      align-items: center;

      .ant-select {
        width: 160px;
        margin-right: 8px;
      }

      .ant-input-search {
        width: 330px;
        margin-right: 8px;

        /deep/ .ant-input-group-addon {
          box-sizing: border-box;
          padding: 0;

          .ant-select.ant-select-enabled {
            width: 98px !important;
            margin-right: 0;
            margin-left: 1px;
          }
        }
      }

      .search-btn {
        width: 84px;
        color: #fff;
        background: #18a8c7;
        border: none;
      }

      .reset-btn {
        width: 82px;
        color: #333;
        background: #fafafa;

        &:hover {
          color: #18a8c7;
        }
      }
    }

    .supplus-pagination {
      display: flex;
      justify-content: space-between;
      height: 64px;
      padding: 16px;
      background: #f3f3f3;
      margin-top: 30px;
      min-width: 1440px;
      width: 98.3%;

      & > div {
        display: flex;
        align-items: center;
      }

      .jump-to-page {
        display: flex;
        align-items: center;

        .ant-input {
          width: 48px;
        }

        span:first-child {
          margin-right: 8px;
        }

        span:last-child {
          margin-left: 8px;
        }
      }
    }
  }

  .new-supllus-dialog {
    .supplus-bread {
      width: 100%;
      height: 38px;
      padding: 0 16px;
      line-height: 38px;
      background: #fafafa;

      span {
        font-family: PingFangSC-Regular, PingFang SC;
      }

      .supplus-bread-home {
        color: #333;
        font-size: 14px;
        cursor: pointer;
      }

      .supplus-bread-final {
        color: #999999;
      }
    }

    .new-supplus-main {
      display: flex;
      justify-content: flex-start;
      height: 673px;
      margin: 24px;
      background: #fff;

      .left-supplus-type {
        flex-shrink: 0;
        width: 208px;
        height: 662px;
        padding: 24px;
        border-right: 2px solid #eee;
        box-shadow: -1px 0px 0px 0px rgba(229, 229, 229, 1);

        .select-shape {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 160px;
          height: 40px;
          margin-bottom: 16px;
          padding: 16px;
          color: #333;
          font-size: 14px;
          cursor: pointer;
        }

        .active-shape {
          color: #fff;
          background: #18a8c7;
        }

        .icon-right {
          color: #fff;
        }
      }

      .right-supplus-form {
        flex-grow: 1;
        flex-shrink: 0;
        min-width: 1184px;
        padding: 24px 24px 0 24px;

        .base-info {
          width: 100%;
          height: 40px;
          margin-bottom: 32px;
          padding-left: 16px;
          color: #333;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          line-height: 40px;
          background: #fafafa;
          border-left: 4px solid #18a8c7;
        }

        .supplus-info-img {
          display: flex;
        }

        .plank-info {
          padding-left: 16px;

          .plank-info-item {
            position: relative;
            display: flex;
            align-items: center;
            margin: 0 24px;
            margin-bottom: 16px;
            /deep/ .el-input__inner {
              height: 32px;
              line-height: 32px;
            }
          }

          .error-info {
            position: absolute;
            top: 32px;
            left: 105px;
            color: #fd3232;
            font-size: 12px;
          }

          .remark-info {
            top: 90px;
          }

          .plank-info-item.remark-form {
            align-items: flex-start;
          }

          .title-box {
            width: 85px;
            margin-right: 2px;
            text-align: right;
          }

          .import {
            flex-shrink: 0;
            color: #fd3232;
            font-size: 14px;
          }

          .title {
            flex-shrink: 0;
            color: #666666;
            font-size: 14px;
          }

          .plank-form-limit {
            color: #b0b0b0;
            font-size: 14px;
          }

          .plank-form-after {
            color: #999999;
            font-size: 14px;
          }

          .remark-input {
            position: relative;

            .remark-limit {
              position: absolute;
              right: 11px;
              bottom: 8px;
              color: #b0b0b0;
              font-size: 14px;
            }
          }

          .new-classify {
            margin-left: 16px;
            color: #18a8c7;
            font-size: 14px;
            cursor: pointer;
          }

          .drop-supplus-menu {
            position: absolute;
            top: 32px;
            left: 84px;
            z-index: 10;
            width: 432px;
            height: 100px;
            max-height: 100px;
            overflow: scroll;
            background: #fff;
            border-radius: 4px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

            &::-webkit-scrollbar {
              display: none;
            }
          }

          .selectMenu-enter-active,
          .selectMenu-leave-active {
            transition: all 0.3s ease;
          }

          .selectMenu-enter,
          .selectMenu-leave-to {
            height: 0;
            max-height: 0;
            opacity: 0;
          }

          .selectMenu-enter-to,
          .selectMenu-leave {
            height: 100px;
            max-height: 100px;
          }

          .errorInfo-enter-active,
          .errorInfo-leave-active {
            transition: all 0.3s ease;
          }

          .errorInfo-enter,
          .errorInfo-leave-to {
            transform: scaleY(0);
            transform-origin: top;
            opacity: 0;
          }

          .errorInfo-enter-to,
          .errorInfo-leave {
            transform: scaleY(1);
            transform-origin: top;
          }

          .drop-supplus-menu-item {
            display: block;
            width: 100%;
            height: 32px;
            padding: 0 16px;
            color: #333;
            font-size: 14px;
            line-height: 32px;
            cursor: pointer;

            &:hover {
              background: #e6f7ff;
            }
          }

          .active-supplus-menu-item {
            background: #e6f7ff;
          }

          .ant-input {
            width: 432px;
          }

          .ant-input-affix-wrapper {
            width: 432px;
          }

          .ant-input-group-wrapper {
            width: 432px;

            /deep/ .ant-input-group-addon {
              width: 76px;
            }
          }

          .alert-input {
            /deep/ .ant-select-selection {
              border-color: #fd3232;
            }

            /deep/ .ant-input {
              border-color: #fd3232;
            }

            &.ant-input {
              border-color: #fd3232;
            }
          }
        }

        .plank-img {
          margin-left: 200px;

          .plank-img-title {
            margin-left: 62px;
            color: #333;
            font-size: 16px;
          }

          .plank-canvas {
            margin-top: 22px;
          }
        }
      }
    }

    .save-supplus-btn {
      display: flex;
      justify-content: center;
      height: 68px;
      margin: 0 24px;
      background: #fff;

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 248px;
      }

      .ant-btn {
        width: 120px;
        height: 32px;
        color: #333;
        font-size: 14px;

        &:last-child {
          color: #fff;
          background: #18a8c7;
        }
      }
    }
  }

  .delete-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    .delete-dialog-content {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 424px;
      height: 170px;
      margin: auto;
      padding: 32px 32px 24px 32px;
      background: #fff;
      border-radius: 2px;
      box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03),
        0px 9px 28px 0px rgba(0, 0, 0, 0.05),
        0px 6px 16px -8px rgba(0, 0, 0, 0.08);

      .delete-title {
        display: flex;
        align-items: center;
        height: 22px;
        margin-bottom: 13px;
      }

      .icon-warn {
        margin-right: 17px;
        color: #faad14;
        font-size: 22px;
      }

      .warm-notice {
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
      }

      .confirm-notice {
        margin-bottom: 24px;
        padding-left: 40px;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
      }

      .delete-btns {
        display: flex;
        justify-content: flex-end;

        > div {
          .ant-btn {
            width: 65px;
            height: 32px;
            color: rgba(0, 0, 0, 0.65);
            font-size: 14px;
          }

          .confirm-btn {
            color: #fff;
            background: #18a8c7;
            border: none;
          }
        }
      }
    }
  }

  .new-classify-dialog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    .new-classify-content {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 424px;
      height: 170px;
      margin: auto;
      padding: 32px 32px 24px 32px;
      background: #fff;
      border-radius: 2px;
      box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03),
        0px 9px 28px 0px rgba(0, 0, 0, 0.05),
        0px 6px 16px -8px rgba(0, 0, 0, 0.08);

      .new-classify-title {
        margin-bottom: 12px;
        color: #333;
        font-size: 16px;
      }

      .new-classify {
        margin-bottom: 16px;
      }

      .new-classify-btns {
        display: flex;
        justify-content: flex-end;

        > div {
          .ant-btn {
            width: 65px;
            height: 32px;
            color: rgba(0, 0, 0, 0.65);
            font-size: 14px;
          }

          .confirm-btn {
            color: #fff;
            background: #18a8c7;
            border: none;
          }
        }
      }
    }
  }

  /deep/ .el-drawer__wrapper {
    z-index: 2004 !important;

    .el-drawer__header {
      margin: 0;
      padding: 0;
    }

    .drawer-title-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      padding: 0 24px;
      border-bottom: 1px solid #ddd;

      .icon-close {
        cursor: pointer;
      }
    }
  }

  /deep/ .v-modal {
    z-index: 2003 !important;
  }

  .surplus-new-plank {
    z-index: 2003;

    .edit-plank-info {
      position: relative;
      height: 100%;
      padding-top: 24px;

      .plank-info-item {
        position: relative;
        display: flex;
        align-items: center;
        margin: 0 15px 24px 20px;
      }

      .error-info {
        position: absolute;
        top: 32px;
        left: 84px;
        color: #fd3232;
        font-size: 12px;
      }

      .remark-info {
        top: 90px;
      }

      .plank-info-item.remark-form {
        align-items: flex-start;
      }

      .title-box {
        width: 85px;
        margin-right: 2px;
        text-align: right;
      }

      .import {
        flex-shrink: 0;
        color: #fd3232;
        font-size: 14px;
      }

      .title {
        flex-shrink: 0;
        color: #666666;
        font-size: 14px;
      }

      .plank-form-limit {
        color: #b0b0b0;
        font-size: 14px;
      }

      .plank-form-after {
        color: #999999;
        font-size: 14px;
      }

      .remark-input {
        position: relative;

        .remark-limit {
          position: absolute;
          right: 11px;
          bottom: 8px;
          color: #b0b0b0;
          font-size: 14px;
        }
      }

      .new-classify {
        margin-left: 16px;
        color: #18a8c7;
        font-size: 14px;
        cursor: pointer;
      }

      .errorInfo-enter-active,
      .errorInfo-leave-active {
        transition: all 0.3s ease;
      }

      .errorInfo-enter,
      .errorInfo-leave-to {
        transform: scaleY(0);
        transform-origin: top;
        opacity: 0;
      }

      .errorInfo-enter-to,
      .errorInfo-leave {
        transform: scaleY(1);
        transform-origin: top;
      }

      .active-supplus-menu-item {
        background: #e6f7ff;
      }

      .ant-input {
        width: 368px;
      }

      .ant-input-affix-wrapper {
        width: 368px;
      }

      .ant-input-group-wrapper {
        width: 368px;

        /deep/ .ant-input-group-addon {
          width: 76px;
        }
      }

      .alert-input {
        /deep/ .ant-select-selection {
          border-color: #fd3232;
        }

        /deep/ .ant-input {
          border-color: #fd3232;
        }

        &.ant-input {
          border-color: #fd3232;
        }
      }

      .edit-bottom-operation {
        // position: absolute;
        // bottom: 0;
        // left: 0;
        display: flex;
        justify-content: center;
        // width: 100%;
        height: 60px;
        // padding: 0 24px;
        border-top: 1px solid #ddd;
        margin-top: 70px;

        > div {
          display: flex;
          align-items: center;
        }

        .ant-btn {
          width: 120px;
          height: 32px;
          margin: 0 16px;

          &:last-child {
            color: #fff;
            background: #18a8c7;
            border: none;
          }
        }
      }
    }
  }

  .loading-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2005;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #0008;
  }
}
.error-info {
  position: absolute;
  top: 30px;
  left: 105px;
  color: #fd3232;
  font-size: 12px;
}

.error-info.remark-info {
  top: 100px;
  left: 150px;
}

/deep/ #supplus_storage_add_type_select {
  height: 32px;
}

/deep/ #supplus_storage_edit_type_select {
  height: 32px;
}

.error-border {
  /deep/ .el-input__inner {
    border-color: red;
  }
}

/deep/.surplus-table {
  .ant-table {
    border: 1px solid #dcdcdc;
    .ant-table-placeholder {
      border-bottom: none;
    }
  }
  .ant-table-thead > tr {
    height: 40px !important;
    > th {
      height: 40px;
      padding: 0;
      background: #f5f5f5;
    }
    > th[key='select'] {
      .ant-table-header-column {
        padding-left: 20px;
      }
    }
    > th[key='branch_name'] {
      .ant-table-column-title {
        overflow: unset;
      }
      .new-button_text {
        top: -13px;
        right: -15px;
      }
    }
    > th[key='operation'] {
      .ant-table-header-column {
        padding-left: 10px;
      }
    }
  }
  .ant-table-tbody > tr {
    height: 40px !important;
    position: relative;
    > td {
      height: 40px;
      padding: 0;
      .copy-line {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 16px;
        height: 40px;
        padding: 0;
        border-bottom-color: #dcdcdc;
      }
      .ant-select-selection__rendered {
        font-size: 12px;
        color: #606266;
      }
      .table-operation-btn {
        color: #18a8c7;
        cursor: pointer;
      }
    }
  }
}
/deep/.branch-name-new {
  .new-button_text {
    top: 0;
    right: 100px;
  }
}
</style>

<style lang="less">
.ant-select-dropdown.supplus-storage-select {
  z-index: 2006;
}

.el-row {
  margin-top: 14px;
}

.el-drawer {
  overflow-y: auto;
}

.active-shape-side {
  color: #ffffff;
  background: #18a8c7;

  span {
    color: white;
  }
}

.select-shape-side {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 160px;
  height: 40px;
  margin-bottom: 16px;
  padding: 16px;
  color: #333;
  font-size: 14px;
  cursor: pointer;
}

.error-info {
  position: absolute;
  top: 60px;
  left: 105px;
  color: #fd3232;
  font-size: 12px;
}

.error-info.remark-info {
  top: 100px;
  left: 150px;
}

.newSideAdd {
  float: right;
  margin-left: 16px;
  color: #18a8c7;
  font-size: 14px;
  line-height: 30px;
  cursor: pointer;
}

.base-info {
  width: 100%;
  height: 40px;
  // margin-bottom: 32px;
  padding-left: 16px;
  color: #333;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  line-height: 40px;
  background: #fafafa;
  border-left: 4px solid #18a8c7;
}

.alert-input {
  .ant-input {
    border-color: red;
  }

  .el-input__inner {
    border-color: red;
  }
}

.ant-input.alert-input {
  border-color: red;
}
.action-btn {
  box-sizing: border-box;
  border-color: #dcdcdc;
  padding-left: 11px;
  padding-right: 14px;

  &:hover {
    color: #18a8c7;
    border-color: #18a8c7;
  }

  /deep/ .ant-checkbox-wrapper {
    color: inherit;
  }
  .ant-checkbox + span {
    padding-right: 0 !important;
  }
}
.action-btn.surplus-lock-btn {
  padding-right: 28px;
}

#supplus_material_color_input {
  width: 318px;
  height: 36px;
}
#supplus_material_edit_color_input {
  width: 370px;
}
.more-operate-popover.ant-popover-placement-bottom {
  width: 79px;
  height: 80px;
  padding-top: 0px;
  margin-top: 5px;
  .ant-popover-arrow {
    display: none;
  }
}
.ant-popover.more-popover {
  width: 193px;
  height: 88px;
  padding-top: 0px;
  margin-top: 5px;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner-content {
    padding: 8px;
  }
}
.batch-lock-popover.ant-popover {
  width: 121px;
  padding-top: 0px;
  margin-top: 10px;
  .ant-popover-arrow {
    display: none;
  }
}
</style>
